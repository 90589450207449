import { LinearProgress, linearProgressClasses, LinearProgressProps, styled, SxProps } from "@mui/material";

interface IProps {
  sx?: SxProps;
  value?: number;
  variant?: LinearProgressProps["variant"];
}

const BorderLinearProgress = ({ sx, value, variant = "determinate" }: IProps) => {
  const StyledLinearProgress = styled(LinearProgress)(() => ({
    height: 4,
    borderRadius: 16,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "rgba(231, 234, 238, 1)",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "rgba(145, 84, 201, 1)",
    },
  }));
  return <StyledLinearProgress variant={variant} value={value} sx={sx} />;
};

export default BorderLinearProgress;
