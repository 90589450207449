import { CreateEventsDto, DailyEvents } from "../model/event.model";
import { Api, ApiResponsePromise, withErrorPage } from "./Api";
import { refreshAccessTokenHook } from "./responseHooks/refreshAccessTokenHook";

class EventApi extends Api {
  getStudentEvents = (id: string, dayFrom: string, dayTo: string): ApiResponsePromise<DailyEvents[]> =>
    this.get(`students/${id}/events?dayFrom=${dayFrom}&dayTo=${dayTo}`, withErrorPage());
  createOrUpdateEvent = (id: string, event: CreateEventsDto): ApiResponsePromise<void> => {
    const { date, eventTypes } = event;
    const requestPayload = { date, eventTypes };
    return this.post(`students/${id}/events`, withErrorPage({ requestPayload, expectJsonResponse: false }));
  };
  deleteEvent = (id: string, date: string): ApiResponsePromise<void> => this.delete(`students/${id}/events/${date}`, withErrorPage({ expectJsonResponse: false }));
}

export const eventApi = new EventApi().useResponseHooks([refreshAccessTokenHook]);
