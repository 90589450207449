import { RatingScalesFormRequest } from "../model/ratingScales.model";
import { Box, Skeleton } from "@mui/material";
import { withLoader } from "../hoc/withLoader";
import RatingScalesRequestsList from "./RatingScalesRequestsList";
import { useEffect, useState } from "react";
import { ratingScalesApi } from "../api/RatingScalesApi";
import { useParams } from "react-router-dom";

export const RECORDS_PER_PAGE = 10;
const LoadingRatingScalesRequestsList = withLoader(RatingScalesRequestsList);

const RatingScalesRequestsListContainer = () => {
  const { id } = useParams();
  const [requests, setRequests] = useState<RatingScalesFormRequest[] | null>(null);

  useEffect(() => {
    const getData = async () => {
      setRequests((await ratingScalesApi.getRatingScalesRequests(id as string)).data);
    };
    setRequests(null);
    getData();
  }, [id]);

  return (
    <LoadingRatingScalesRequestsList requests={requests as RatingScalesFormRequest[]} isLoading={requests === null}>
      <Box sx={{ height: 16, display: "flex", direction: "row", padding: "12px 16px", gap: "28px", minWidth: "1000px" }}>
        <Box sx={{ height: "inherit", flex: 2, minWidth: "160px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", width: "65px", marginLeft: "48px" }} />
        </Box>
        <Box sx={{ height: "inherit", flex: 1, minWidth: "112px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", width: "85px" }} />
        </Box>
        <Box sx={{ height: "inherit", flex: 1, minWidth: "100px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", width: "90px" }} />
        </Box>
        <Box sx={{ height: "inherit", flex: 1, minWidth: "100px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", width: "70px" }} />
        </Box>
        <Box sx={{ height: "inherit", flex: 2, minWidth: "160px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", width: "50px" }} />
        </Box>
      </Box>
      {Array(RECORDS_PER_PAGE)
        .fill(1)
        .map((el, i) => (
          <Box key={i} sx={{ height: 44, paddingTop: "2px", paddingBottom: "6px", minWidth: "1000px" }}>
            <Skeleton variant="rounded" sx={{ height: "inherit" }} />
          </Box>
        ))}
    </LoadingRatingScalesRequestsList>
  );
};

export default RatingScalesRequestsListContainer;
