import { makeAutoObservable } from "mobx";
import {
  QuestionsCount,
  RatingScalesFormPageContent,
  RatingScalesFormQuestion,
  RatingScalesFormQuestionType,
  RatingScalesFormSectionPage,
  RatingScalesFormStatus,
  RatingScalesFormSummary,
} from "../model/ratingScales.model";
import { ApiError } from "../api/Api";

export class RatingScaleFormState {
  private _page: RatingScalesFormPageContent | null = null;
  private _questionsMap: Map<string, RatingScalesFormQuestion> = new Map();
  private _syncedAnswers: Map<string, string> = new Map();
  private _firstQuestionNumber: number | null = null;
  private _lastQuestionNumber: number | null = null;
  private _formSummary: RatingScalesFormSummary | null = null;
  private _loading = 0;
  private _switchingPages = false;
  private _apiError: ApiError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  set page(value: RatingScalesFormPageContent | null) {
    this._page = value;
    this._loading = 0;
    this._questionsMap.clear();
    this._syncedAnswers.clear();
    this._lastQuestionNumber = null;
    this._firstQuestionNumber = null;
    this._apiError = null;
    if (this._page) {
      this._page.questions.forEach((question) => this._questionsMap.set(question.uuid, question));
      this._page.questions.forEach((question) => this._syncedAnswers.set(question.uuid, this.getAnswer(question) || ""));
      const questionNumbers: number[] = this._page?.questions
        .filter((question) => question.order !== undefined)
        .map((question) => question.order as number)
        .slice()
        .sort((a, b) => a - b);
      this._firstQuestionNumber = questionNumbers.shift() || null;
      this._lastQuestionNumber = questionNumbers.pop() || null;
    }
  }

  get page(): RatingScalesFormPageContent | null {
    return this._page;
  }

  get firstQuestionNumber(): number | null {
    return this._firstQuestionNumber;
  }

  get lastQuestionNumber(): number | null {
    return this._lastQuestionNumber;
  }

  set formSummary(value: RatingScalesFormSummary | null) {
    this._formSummary = value;
  }

  get allFormQuestionsAnswered() {
    if (!this._page) {
      return false;
    }
    return this._page && this._page?.totalCount === this._page?.completedCount;
  }

  get isFormSubmitted() {
    if (!this._formSummary) {
      return false;
    }
    return this._formSummary && this._formSummary.status === RatingScalesFormStatus.COMPLETED;
  }

  get isPageCompleted() {
    if (!this._page) {
      return false;
    }
    return this._page.questions.every((question) => !this.isQuestionRequired(question) || this.isQuestionCompleted(question));
  }

  set loading(value: number) {
    this._loading = value;
  }

  get loading() {
    return this._loading;
  }

  set switchingPages(value: boolean) {
    this._switchingPages = value;
  }

  get switchingPages() {
    return this._switchingPages;
  }

  get apiError() {
    return this._apiError;
  }

  set apiError(value: ApiError | null) {
    this._apiError = value;
  }

  reset = () => {
    this._page = null;
    this._formSummary = null;
  };

  getPreviousAndNextPageUuids = (
    pageUuid: string
  ): {
    previousUuid: string | null;
    nextUuid: string | null;
  } => {
    const pageIndex = this.findPageIndex(this.orderedPages, pageUuid);
    return {
      previousUuid: this.orderedPages[pageIndex - 1]?.uuid || null,
      nextUuid: this.orderedPages[pageIndex + 1]?.uuid || null,
    };
  };

  findQuestion = (uuid: string) => {
    const question = this._questionsMap.get(uuid);
    if (!question) {
      throw new Error(`Question not found, uuid: ${uuid}`);
    }
    return question;
  };

  updateAnswer = (question: RatingScalesFormQuestion, answer: string) => {
    switch (question.type) {
      case RatingScalesFormQuestionType.CODING:
        question.selectedAnswer = answer;
        break;
      case RatingScalesFormQuestionType.STRING:
      case RatingScalesFormQuestionType.TEXT:
        question.textAnswer = answer;
        break;
      default:
        throw new Error(`Unsupported question type: ` + question.type);
    }
  };

  getAnswer = (question: RatingScalesFormQuestion) => {
    switch (question.type) {
      case RatingScalesFormQuestionType.CODING:
        return question.selectedAnswer;
      case RatingScalesFormQuestionType.STRING:
      case RatingScalesFormQuestionType.TEXT:
        return question.textAnswer;
      default:
        throw new Error(`Unsupported question type: ` + question.type);
    }
  };

  getSyncedAnswer = (questionId: string) => this._syncedAnswers.get(questionId);

  updateSyncedAnswer = (questionId: string, answer: string) => this._syncedAnswers.set(questionId, answer);

  updateQuestionsCount = (questionsCount: QuestionsCount) => {
    if (this._page) {
      this._page.completedCount = questionsCount.completed;
      this._page.totalCount = questionsCount.total;
    }
  };

  private get orderedPages(): RatingScalesFormSectionPage[] {
    if (!this._formSummary) {
      return [];
    }
    const allPages: RatingScalesFormSectionPage[] = [];
    this._formSummary.sections.forEach((section) => {
      const sectionPages = section.pages.map((page) => ({ ...page, order: page.order + allPages.length }));
      allPages.push(...sectionPages);
    });
    return allPages.slice().sort((page1, page2) => page1.order - page2.order);
  }

  private findPageIndex = (pages: RatingScalesFormSectionPage[], pageUuid: string) => pages.findIndex((page) => page.uuid === pageUuid);

  private isQuestionCompleted = (question: RatingScalesFormQuestion) => {
    switch (question.type) {
      case RatingScalesFormQuestionType.CODING:
        return !!question.selectedAnswer;
      case RatingScalesFormQuestionType.STRING:
      case RatingScalesFormQuestionType.TEXT:
        return !!question.textAnswer;
      default:
        throw new Error(`Unsupported question type: ` + question.type);
    }
  };

  private isQuestionRequired = (question: RatingScalesFormQuestion) => {
    if (question.required) {
      return true;
    }
    if (question.enableWhenAny) {
      return question.enableWhenAny.some((condition) => {
        const relatedQuestion = this.findQuestion(condition.questionUuid);
        return relatedQuestion.selectedAnswer === condition.answerCode;
      });
    }
    return false;
  };
}
