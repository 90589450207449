import { Box, Typography } from "@mui/material";
import MuiAvatar from "@mui/material/Avatar";

interface AvatarProps {
  firstName: string;
  lastName: string;
  email: string;
  imgSrc?: string;
}

const Avatar = ({ firstName, lastName, email, imgSrc }: AvatarProps) => {
  return (
    <Box sx={{ display: "flex" }}>
      <MuiAvatar src={imgSrc} alt={`${firstName} ${lastName}`} sx={{ width: "40px", height: "40px" }} />
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "8px" }}>
        <Typography sx={{ fontSize: "14px", color: "rgba(78, 93, 105, 1)", lineHeight: "16px" }}>
          {firstName} {lastName}
        </Typography>
        <Typography sx={{ fontSize: "12px", color: "rgba(179, 187, 196, 1)", lineHeight: "16px", whiteSpace: "nowrap" }}>{email}</Typography>
      </Box>
    </Box>
  );
};

export default Avatar;
