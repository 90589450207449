import { Box } from "@mui/material";
import TextHeader from "../components/TextHeader";
import { useTranslation } from "react-i18next";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import DefaultSecondaryButton from "../components/DefaultSecondaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import DefaultInput from "../components/DefaultInput";
import { useState } from "react";
import { passwordValidationPatterns, validate } from "../util/validation";
import DefaultText from "../components/DefaultText";
import LeaveConfirmation from "../components/LeaveConfirmation";
import DefaultModal from "../components/DefaultModal";
import { observer } from "mobx-react-lite";
import PasswordChanged from "../components/PasswordChanged";
import { userApi } from "../api/UserApi";
import { useClosePageModal } from "../util/closePageModal";
import { getErrorState } from "../state/ErrorState";

const CreateNewPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const disabledContinueBtn = passwordError.length > 0 || confirmPasswordError.length > 0 || !password || !confirmPassword || loading;
  const errorState = getErrorState();
  const token = searchParams.get("token")?.replace(/ /g, "+");
  const email = searchParams.get("email")?.replace(/ /g, "+");
  const code = searchParams.get("code");

  useClosePageModal(password);

  const handleCancel = () => {
    if (password || confirmPassword) {
      setIsModalOpen(true);
    } else {
      navigate("/login");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleContinue = async () => {
    if (!disabledContinueBtn) {
      setLoading(true);
      const response = await userApi.resetPasswordConfirm(email as string, token as string, password, code as string);
      if (response.ok) {
        setPasswordChanged(true);
      } else {
        errorState.code = response.error.code;
      }
      setLoading(false);
    }
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);
    const error = validate(value, passwordValidationPatterns);
    if (error) {
      setPasswordError(t(error));
    } else if (confirmPassword && confirmPassword !== value) {
      setPasswordError("");
      setConfirmPasswordError(t("passwords-dont-match"));
    } else if (confirmPassword === value) {
      setPasswordError("");
      setConfirmPasswordError("");
    } else {
      setPasswordError("");
    }
  };

  const onConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    if (password !== value) {
      setConfirmPasswordError(t("passwords-dont-match"));
    } else if (password === value) {
      setPasswordError("");
      setConfirmPasswordError("");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleLeave = () => {
    navigate("/login");
  };

  return (
    <Box sx={{ paddingTop: "160px" }}>
      {!passwordChanged ? (
        <>
          <TextHeader>{t("restore-password")}</TextHeader>
          <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", paddingTop: "10px" }}>{t("enter-your-new-password")}</DefaultText>
          <DefaultInput value={email as string} label={t("email")} disabled sx={{ width: "100%", marginTop: "34px" }} />
          <DefaultInput
            label={t("password")}
            error={passwordError.length > 0}
            errorMsg={passwordError}
            value={password}
            password
            onChange={onPasswordChange}
            onSubmit={handleContinue}
            sx={{ width: "100%", marginTop: "34px" }}
          />
          <DefaultInput
            label={t("repeat-password")}
            error={confirmPasswordError.length > 0}
            errorMsg={confirmPasswordError}
            value={confirmPassword}
            onChange={onConfirmPasswordChange}
            password
            disabled={passwordError.length > 0 || !password}
            onSubmit={handleContinue}
            sx={{ width: "100%", marginTop: "34px" }}
          />
          <DefaultPrimaryButton onClick={handleContinue} disabled={disabledContinueBtn} sx={{ width: "288px", marginTop: "42px" }} isLoading={loading}>
            {t("continue")}
          </DefaultPrimaryButton>
          <DefaultSecondaryButton
            onClick={handleCancel}
            sx={{ border: "none", padding: "0", marginTop: "60px", height: "inherit", "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" } }}
          >
            {t("cancel")}
          </DefaultSecondaryButton>
          <DefaultModal modalTitle={t("leaving-the-page")} isOpen={isModalOpen} onClose={handleCloseModal}>
            <LeaveConfirmation handleClose={handleCloseModal} handleLeave={handleLeave} />
          </DefaultModal>
        </>
      ) : (
        <PasswordChanged />
      )}
    </Box>
  );
};

export default observer(CreateNewPasswordPage);
