import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface CheckCircleIconProps {
  sx?: SxProps;
}

const CheckCircleIcon = ({ sx }: CheckCircleIconProps) => <DefaultIcon src={"checkCircle.svg"} sx={{ width: "24px", height: "24px", ...sx }} />;

export default CheckCircleIcon;
