import { makeAutoObservable } from "mobx";

export enum StudentProfileTab {
  EVENTS,
  RATING_SCALES,
}

export enum ReportPeriod {
  THIRTY_DAYS = "30",
  SIXTY_DAYS = "60",
  NINETY_DAYS = "90",
}

export class StudentProfilePageState {
  private _activeTab: StudentProfileTab = StudentProfileTab.EVENTS;
  private _numberOfDays: ReportPeriod = ReportPeriod.THIRTY_DAYS;
  constructor() {
    makeAutoObservable(this);
  }

  set activeTab(tab: StudentProfileTab) {
    this._activeTab = tab;
  }

  get activeTab(): StudentProfileTab {
    return this._activeTab;
  }

  isActive(tab: StudentProfileTab) {
    return this._activeTab === tab;
  }

  set numberOfDays(value: ReportPeriod) {
    this._numberOfDays = value;
  }

  get numberOfDays(): ReportPeriod {
    return this._numberOfDays;
  }
}
