import { Box, SxProps } from "@mui/material";
import { useAppState } from "../state/AppState";
import { StudentProfileTab } from "../state/StudentProfilePageState";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import DefaultTabs from "./DefaultTabs";
import EventsTypeSelectorTab from "./EventsTypeSelectorTab";
import RatingScalesTab from "./RatingScalesTab";
import { useParams } from "react-router-dom";

const StudentProfileTabs = ({ sx }: { sx: SxProps }) => {
  const { t } = useTranslation();
  const { studentProfilePageState } = useAppState();
  const { id } = useParams();

  const tabsData = [
    {
      value: StudentProfileTab.EVENTS,
      label: t("events"),
      link: `/students/${id}`,
    },
    {
      value: StudentProfileTab.RATING_SCALES,
      label: t("rating-scales"),
      link: `/students/${id}/ratingScales`,
    },
  ];

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      studentProfilePageState.activeTab = tabIndex;
    },
    [studentProfilePageState]
  );

  return (
    <Box sx={{ ...sx }}>
      <DefaultTabs data={tabsData} value={studentProfilePageState.activeTab} onChange={handleTabChange} />
      <Box
        sx={{
          backgroundColor: "rgba(249, 249, 249, 1)",
          padding: "40px 32px",
          border: "1px solid rgba(180, 116, 237, 1)",
          borderRadius: "0px 8px 8px 8px",
        }}
      >
        {studentProfilePageState.isActive(StudentProfileTab.EVENTS) && <EventsTypeSelectorTab />}
        {studentProfilePageState.isActive(StudentProfileTab.RATING_SCALES) && <RatingScalesTab />}
      </Box>
    </Box>
  );
};

export default observer(StudentProfileTabs);
