import { SxProps } from "@mui/material";
import { QuestionComponentProps } from "./model/ratingScales.types";
import RatingScaleAnswerSelector from "./RatingScaleAnswerSelector";
import RatingScaleQuestionLayout from "./RatingScaleQuestionLayout";

const RatingScaleQuestionWithOptions = (props: QuestionComponentProps & { sx?: SxProps }) => {
  return (
    <RatingScaleQuestionLayout {...props}>
      <RatingScaleAnswerSelector {...props} />
    </RatingScaleQuestionLayout>
  );
};

export default RatingScaleQuestionWithOptions;
