import DefaultIcon from "./DefaultIcon";
import { SxProps } from "@mui/material";

interface IProps {
  sx?: SxProps;
}

const ChevronRightBlackIcon = ({ sx }: IProps) => <DefaultIcon src={"chevronRightBlack.svg"} sx={sx} />;

export default ChevronRightBlackIcon;
