import { Box } from "@mui/material";
import { t } from "i18next";
import DefaultModal from "./DefaultModal";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultText from "./DefaultText";
import { useState } from "react";
import { ratingScalesApi } from "../api/RatingScalesApi";
import PaperPlaneIcon from "./icons/PaperPlaneIcon";
import { useAppState } from "../state/AppState";

interface RatingScaleSubmitFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  formId: string;
  studentId: string;
}

const RatingScaleSubmitFormModal = ({ isOpen, formId, studentId, onClose, onSubmit }: RatingScaleSubmitFormModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { ratingScaleFormState } = useAppState();
  const handleSubmit = async () => {
    setLoading(true);
    const submitResult = await ratingScalesApi.submitForm(studentId, formId);
    setLoading(false);
    if (submitResult.ok) {
      onSubmit();
    } else {
      onClose();
      ratingScaleFormState.apiError = submitResult.error;
    }
  };

  const handleClose = () => {
    setLoading(false);
    onClose();
  };

  return (
    <DefaultModal modalTitle={t("submit-form")} isOpen={isOpen} onClose={handleClose} titleIcon={<PaperPlaneIcon />}>
      <DefaultText sx={{ fontSize: "16px" }}>{t("submit-form-confirmation")}</DefaultText>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "16px", paddingTop: "40px" }}>
        <DefaultSecondaryButton onClick={handleClose} sx={{ height: "44px", minWidth: "84px" }}>
          {t("review-the-form")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleSubmit} isLoading={loading} disabled={loading} sx={{ height: "44px", minWidth: "100px" }}>
          {t("submit")}
        </DefaultPrimaryButton>
      </Box>
    </DefaultModal>
  );
};

export default RatingScaleSubmitFormModal;
