import { Box } from "@mui/material";
import Link from "./components/Link";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useAppState } from "./state/AppState";
import { Page } from "./state/NavigationState";

const NavigationElement = ({ selected, children, to }: { selected?: boolean; children: string; to: string }) => (
  <Link
    sx={{
      paddingLeft: "25px",
      paddingRight: "25px",
      fontWeight: selected ? "700" : "400",
      "&:hover": {
        color: "rgba(102, 56, 158, 1)",
      },
    }}
    to={to}
  >
    <Box
      sx={{
        borderBottom: selected ? "3px solid rgba(145, 84, 201, 1)" : undefined,
        boxSizing: "border-box",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      {children}
    </Box>
  </Link>
);

const TopNavigation = () => {
  const { t } = useTranslation();
  const { navigation } = useAppState();
  return (
    <Box sx={{ display: "flex", height: "inherit" }}>
      <NavigationElement selected={navigation.isActive(Page.MY_STUDENTS)} to="/students">
        {t("my-students")}
      </NavigationElement>
      <NavigationElement selected={navigation.isActive(Page.HELP_CENTER)} to="/help-center">
        {t("help-center")}
      </NavigationElement>
    </Box>
  );
};

export default observer(TopNavigation);
