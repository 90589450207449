import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAppState } from "./state/AppState";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Link from "./components/Link";
import DefaultText from "./components/DefaultText";
import separator from "./assets/img/breadcrumbSeparator.svg";

const Breadcrumbs = () => {
  const { navigation } = useAppState();
  const { t } = useTranslation();
  const elements = navigation.breadcrumbsElements;
  return (
    <Box
      sx={{
        height: "50px",
        display: "flex",
        alignItems: "center",
        position: "static",
        backgroundColor: "rgba(255, 255, 255, 1)",
        paddingLeft: "5%",
        paddingRight: "5%",
        boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
        borderTop: "1px solid rgba(231, 234, 238, 1)",
      }}
    >
      <MuiBreadcrumbs sx={{ fontSize: "14px" }} separator={<Box component="img" src={separator} sx={{ width: "16px", height: "16px", marginTop: "2px" }} />}>
        {elements.map((element) => {
          if (element.path) {
            return (
              <Link
                to={element.path}
                sx={{
                  color: "rgba(145, 84, 201, 1)",
                  "&:hover": {
                    color: "rgba(102, 56, 158, 1)",
                  },
                }}
                key={element.labelId}
              >
                {t(element.labelId)}
              </Link>
            );
          } else {
            return <DefaultText key={element.labelId}>{t(element.labelId)}</DefaultText>;
          }
        })}
      </MuiBreadcrumbs>
    </Box>
  );
};

export default observer(Breadcrumbs);
