import { Box, Link, Modal, SxProps } from "@mui/material";
import React from "react";
import DefaultText from "./DefaultText";
import CloseIcon from "./icons/CloseIcon";

interface DefaultModalProps {
  modalTitle: string;
  titleIcon?: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  sx?: SxProps;
}

const DefaultModal = ({ modalTitle, children, isOpen, onClose, titleIcon, sx }: DefaultModalProps) => {
  return (
    <>
      <Modal open={isOpen} onClose={onClose} sx={{ fontSize: "12px", color: "rgba(112, 124, 136, 1)" }}>
        <Box
          sx={{
            width: "580px",
            padding: "40px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "8px",
            background: "#FFFFFF",
            boxShadow: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
            maxHeight: "600px",
            overflowY: "auto",
            ...sx,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "24px",
              marginBottom: "24px",
              borderBottom: "1px solid rgba(179, 187, 196, 1)",
            }}
          >
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              {titleIcon}
              <DefaultText sx={{ fontSize: "21px", lineHeight: "24px" }}>{modalTitle}</DefaultText>
            </Box>

            {onClose && (
              <Link component="button" onClick={onClose}>
                <CloseIcon />
              </Link>
            )}
          </Box>
          {children}
        </Box>
      </Modal>
    </>
  );
};

export default DefaultModal;
