import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface FileTextIconProps {
  sx?: SxProps;
}

const FileTextBlackIcon = ({ sx }: FileTextIconProps) => <DefaultIcon src={"fileTextBlack.svg"} sx={{ width: "24px", height: "24px", ...sx }} />;

export default FileTextBlackIcon;
