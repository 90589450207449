import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultText from "./DefaultText";

const RatingScalesTab = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <DefaultText sx={{ fontSize: "18px", color: "rgba(78, 93, 105, 1)", fontWeight: "700", marginBottom: "10px" }}>{t("rating-scales-tab-header")}</DefaultText>
      <DefaultText sx={{ fontSize: "16px", color: "rgba(78, 93, 105, 1)" }}>{t("rating-scales-tab-message")}</DefaultText>
    </Box>
  );
};

export default RatingScalesTab;
