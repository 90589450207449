import { Box, SxProps } from "@mui/material";

interface ChipListProps {
  items: React.ReactNode[];
  sx?: SxProps;
}

const ChipList = ({ items, sx }: ChipListProps) => {
  return <Box sx={{ display: "flex", gap: "2px", flexWrap: "wrap", ...sx }}>{items}</Box>;
};

export default ChipList;
