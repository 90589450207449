import { Box } from "@mui/material";
import { useEffect } from "react";
import { useAppState } from "../state/AppState";
import { Page } from "../state/NavigationState";

const HelpCenterPage = () => {
  const { navigation } = useAppState();
  useEffect(() => {
    navigation.activePage = Page.HELP_CENTER;
    navigation.breadcrumbsElements = [
      {
        labelId: "help-center",
        path: "/help-center",
      },
    ];
  }, [navigation]);
  return <Box>TODO: Help center Page</Box>;
};

export default HelpCenterPage;
