import { Box } from "@mui/material";
import DefaultText from "../components/DefaultText";
import TextHeader from "../components/TextHeader";
import TextLabel from "../components/TextLabel";
import RegistrationStepper from "../components/RegistrationStepper";
import { useTranslation } from "react-i18next";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import DefaultSecondaryButton from "../components/DefaultSecondaryButton";
import { useState, useRef, useEffect } from "react";
import { useAppState } from "../state/AppState";
import { useNavigate } from "react-router-dom";

const TermsAndConditionsPage = () => {
  const { t } = useTranslation();
  const [isRead, setIsRead] = useState(false);
  const textEnd = useRef(null);
  const { user } = useAppState();
  const navigate = useNavigate();

  const handleDeclined = () => {
    user.acceptedTermsAndConditions = false;
    navigate(`/register/step1/declined${user.invitationUrl()}`);
  };

  const handleAccept = () => {
    user.acceptedTermsAndConditions = true;
    navigate(`/register/step2${user.invitationUrl()}`);
  };

  useEffect(() => {
    if (isRead) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsRead(entry.isIntersecting);
      },
      { root: null, rootMargin: "0px", threshold: 0 }
    );

    if (textEnd.current) {
      observer.observe(textEnd.current);
    }

    return () => {
      if (textEnd.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(textEnd.current);
      }
    };
  }, [isRead]);

  return (
    <Box sx={{ paddingTop: "47px", width: "1024px" }}>
      <TextLabel sx={{ paddingBottom: "2px", color: "rgba(78, 93, 105, 1)" }}>{t("register")}</TextLabel>
      <TextHeader sx={{ paddingBottom: "24px" }}>{t("terms-and-conditions")}</TextHeader>
      <RegistrationStepper step={1} />
      <Box sx={{ height: "470px", overflowY: "auto" }}>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>
          These Terms and Conditions of Use (the &ldquo;Terms of Use&ldquo;) apply to the Oysta web site located at www.Oysta.com, and all associated sites linked to www.Oysta.com
          by Oysta, its subsidiaries and affiliates, including Oysta sites around the world (collectively, the &ldquo;Site&ldquo;). The Site is the property of Oysta Inc.
          (&ldquo;Oysta&ldquo;) and its licensors. BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT AGREE, DO NOT USE THE SITE. Oysta reserves the right, at its
          sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It is your responsibility to check these Terms of Use periodically for
          changes. Your continued use of the Site following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these Terms of
          Use, Oysta grants you a personal, non-exclusive, non-transferable, limited privilege to enter and use the Site.
        </DefaultText>
        <TextHeader sx={{ paddingTop: "32px", paddingBottom: "24px" }}>Content</TextHeader>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>
          All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code (collectively, &ldquo;Content&ldquo;),
          including but not limited to the design, structure, selection, coordination, expression, &ldquo;look and feel&ldquo; and arrangement of such Content, contained on the
          Site is owned, controlled or licensed by or to Oysta, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property
          rights and unfair competition laws. Except as expressly provided in these Terms of Use, no part of the Site and no Content may be copied, reproduced, republished,
          uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including &ldquo;mirroring&ldquo;) to any other computer, server, Web
          site or other medium for publication or distribution or for any commercial enterprise, without Oysta’s express prior written consent. You may use information on Oysta
          products and services (such as data sheets, knowledge base articles, and similar materials) purposely made available by Oysta for downloading from the Site, provided that
          you (1) not remove any proprietary notice language in all copies of such documents, (2) use such information only for your personal, non-commercial informational purpose
          and do not copy or post such information on any networked computer or broadcast it in any media, (3) make no modifications to any such information, and (4) not make any
          additional representations or warranties relating to such documents.
        </DefaultText>
        <Box sx={{ height: "1px" }} ref={textEnd} />
      </Box>
      <Box sx={{ paddingTop: "50px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DefaultSecondaryButton onClick={handleDeclined} sx={{ border: "none", padding: "0", height: "inherit", "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" } }}>
          {t("i-dont-agree")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleAccept} disabled={!isRead} sx={{ width: "240px" }}>
          {t("i-accept-and-continue")}
        </DefaultPrimaryButton>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsPage;
