import { Box, SxProps } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { RatingScalesFormSection, RatingScalesFormStatus, RatingScalesFormSummary } from "../model/ratingScales.model";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import DefaultText from "./DefaultText";
import Link from "./Link";
import { formatTimestamp } from "../util/date";
import FileTextBlackIcon from "./icons/FileTextBlackIcon";
import BorderLinearProgress from "./BorderLinearProgress";
import WarningPurpleIcon from "./icons/WarningPurpleIcon";
import CheckCirclePurpleIcon from "./icons/CheckCirclePurpleIcon";
import BackToRatingScalesLink from "./BackToRatingScalesLink";
import RatingScalesFormInfoCard from "./RatingScalesFormInfoCard";
import RatingScaleFormInfoSectionItem from "./RatingScaleFormInfoSectionItem";
import { useParams } from "react-router-dom";

interface RatingScalesRequestsListProps {
  ratingScalesRequestForm: RatingScalesFormSummary;
}

const RatingScalesRequestsFormInfo = ({ ratingScalesRequestForm }: RatingScalesRequestsListProps) => {
  const { t } = useTranslation();
  const { id, ratingScaleId } = useParams();
  const completedPercent = (ratingScalesRequestForm.completedCount / ratingScalesRequestForm.totalCount) * 100;

  const isDisabledSection = (currentSection: RatingScalesFormSection, sections: RatingScalesFormSection[]): boolean => {
    const isFirstUnanswered = currentSection.order === 1 && currentSection.completedCount === 0;
    const isInProgress = currentSection.completedCount > 0;
    const isFullyAnswered = currentSection.completedCount === currentSection.totalCount;
    const prevSection = sections.find((section) => section.order === currentSection.order - 1);
    const nextAfterFullyAnswered = !!prevSection && prevSection.completedCount === prevSection.totalCount;

    return !(isFirstUnanswered || isInProgress || isFullyAnswered || nextAfterFullyAnswered);
  };

  const renderActionButton = (ratingScalesRequestForm: RatingScalesFormSummary): React.ReactNode => {
    const linkBase = `/students/${id}/ratingScales/${ratingScaleId}/pages/`;
    const firstPageUuid = ratingScalesRequestForm.sections[0].pages[0].uuid;
    const getUnansweredPageUuid = (): string => {
      let pageUuid;
      for (const section of ratingScalesRequestForm.sections) {
        for (const page of section.pages) {
          if (!page.isCompleted) {
            pageUuid = page.uuid;
            break;
          }
        }
        if (pageUuid) {
          break;
        }
      }
      if (!pageUuid) {
        return ratingScalesRequestForm.sections.slice(-1)[0].pages.slice(-1)[0].uuid;
      }

      return pageUuid;
    };
    const buttonSx: SxProps = { height: "40px", fontSize: "14px", padding: "10px 16px", borderRadius: "8px", minWidth: "140px" };
    switch (ratingScalesRequestForm.status) {
      case RatingScalesFormStatus.COMPLETED:
        return (
          <Link to={`${linkBase}${firstPageUuid}`}>
            <DefaultPrimaryButton
              sx={{
                ...buttonSx,
                backgroundColor: "rgba(255, 255, 255, 1)",
                color: "rgba(145, 84, 201, 1)",
                border: "1px solid #9154C9",
                "&:hover": {
                  backgroundColor: "rgba(232, 229, 255, 1)",
                },
              }}
            >
              {t("view")}
            </DefaultPrimaryButton>
          </Link>
        );
      case RatingScalesFormStatus.IN_PROGRESS:
        return (
          <Link to={`${linkBase}${getUnansweredPageUuid()}`}>
            <DefaultPrimaryButton sx={buttonSx}>{t("continue")}</DefaultPrimaryButton>
          </Link>
        );
      case RatingScalesFormStatus.REQUESTED:
        return (
          <Link to={`${linkBase}${firstPageUuid}`}>
            <DefaultPrimaryButton sx={buttonSx}>{t("fill-form")}</DefaultPrimaryButton>
          </Link>
        );
      default:
        return <></>;
    }
  };

  const renderLicenceText = (form: RatingScalesFormSummary): React.ReactNode => {
    const licenseLink = (
      <Link to={form.licenseUrl} sx={{ color: "#9154C9", fontWeight: 700, textDecorationLine: "underline" }}>
        {form.licenseUrlText}
      </Link>
    );
    const licenseText = form.licenseText.split(form.licenseUrlText).reduce<(string | React.ReactNode)[]>((elements, textPart, index) => {
      if (index === 0) {
        return [textPart];
      }
      return elements.concat(licenseLink, textPart);
    }, []);
    return (
      <DefaultText sx={{ fontSize: "14px" }}>
        {licenseText.map((textPart, index) => (
          <span key={index}>{textPart}</span>
        ))}
      </DefaultText>
    );
  };

  return (
    <Box sx={{ paddingBottom: "25px" }}>
      <BackToRatingScalesLink />
      <Box
        sx={{
          display: "grid",
          gap: "22px",
          gridTemplateColumns: "repeat(2, 1fr)",
          position: "relative",
          padding: "17px 24px 13px 24px",
          borderRadius: "7px 7px 0px 0px",
          border: "1px solid rgba(213, 219, 227, 1)",
          backgroundColor: "rgba(247, 248, 251, 1)",
          marginBottom: "-4px",
        }}
      >
        <RatingScalesFormInfoCard
          typeTitle={ratingScalesRequestForm.typeTitle}
          createdBy={ratingScalesRequestForm.createdBy}
          creationTimestamp={ratingScalesRequestForm.creationTimestamp}
        />
        <Box
          sx={{
            display: "flex",
            gap: "18px",
            padding: "16px 32px",
            alignItems: "center",
            justifyContent: "flex-end",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            border: "1px solid rgba(213, 219, 227, 1)",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <DefaultText sx={{ fontSize: "18px", fontWeight: 700 }}>
              <Trans
                i18nKey="completed-questions"
                components={{ span: <span style={{ color: "#9154C9" }}></span> }}
                values={{ completedCount: ratingScalesRequestForm.completedCount, totalCount: ratingScalesRequestForm.totalCount }}
              />
            </DefaultText>
            <BorderLinearProgress value={completedPercent} sx={{ marginTop: "8px" }} />
            {ratingScalesRequestForm.status === RatingScalesFormStatus.IN_PROGRESS && ratingScalesRequestForm.completedCount === ratingScalesRequestForm.totalCount ? (
              <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                <WarningPurpleIcon sx={{ width: "16px", height: "16px" }} />
                <DefaultText sx={{ fontSize: "12px" }}>{t("the-form-is-not-submitted-click-continue-to-submit-the-form")}</DefaultText>
              </Box>
            ) : null}
            {ratingScalesRequestForm.submissionTimestamp && (
              <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                <CheckCirclePurpleIcon sx={{ width: "16px", height: "16px" }} />
                <DefaultText sx={{ fontSize: "12px" }}>
                  <Trans i18nKey="you-submitted-this-form-on" values={{ submissionDate: formatTimestamp(ratingScalesRequestForm.submissionTimestamp, "MM/DD/YYYY") }} />
                </DefaultText>
              </Box>
            )}
          </Box>
          <Box>{renderActionButton(ratingScalesRequestForm)}</Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "16px",
          backgroundColor: "rgba(255, 255, 255, 1)",
          padding: "16px 32px 4px 32px",
          borderRadius: "7px",
          border: "1px solid rgba(213, 219, 227, 1)",
          borderTop: "1px solid rgba(213, 219, 227, 1)",
        }}
      >
        {ratingScalesRequestForm.sections.map((section, index, sections) => (
          <RatingScaleFormInfoSectionItem key={index} section={section} disabled={isDisabledSection(section, sections)} />
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {renderLicenceText(ratingScalesRequestForm)}
        <Link to={"#"} sx={{ marginLeft: "auto", display: "flex", alignItems: "center", color: "#9154C9", textDecorationLine: "underline" }}>
          <DefaultText sx={{ fontSize: "14px", fontWeight: 700, color: "#9154C9" }}>{t("view-form-guide")}</DefaultText>
          <FileTextBlackIcon sx={{ marginLeft: "8px" }} />
        </Link>
      </Box>
    </Box>
  );
};

export default RatingScalesRequestsFormInfo;
