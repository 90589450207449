import { Box } from "@mui/material";
import Logo from "./components/Logo";
import BellIcon from "./components/icons/BellIcon";
import SettingsIcon from "./components/icons/SettingsIcon";
import Avatar from "./components/Avatar";
import TopNavigation from "./TopNavigation";
import UserProfileMenu from "./components/UserProfileMenu";

const Header = () => {
  return (
    <Box
      sx={{
        height: "80px",
        display: "flex",
        alignItems: "center",
        position: "static",
        backgroundColor: "rgba(255, 255, 255, 1)",
        paddingLeft: "5%",
        paddingRight: "5%",
        boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
      }}
    >
      <Box sx={{ flex: 0.5 }}>
        <Logo />
      </Box>
      <Box sx={{ flex: 1, height: "100%" }}>
        <TopNavigation />
      </Box>
      <Box sx={{ flex: 0.5, justifyContent: "flex-end", alignItems: "center", display: "flex", gap: "32px" }}>
        <BellIcon />
        <SettingsIcon />
        <Avatar firstName="Alex" lastName="Rogan" email="alex.rogan@general-hospital.com" />
        <UserProfileMenu />
      </Box>
    </Box>
  );
};

export default Header;
