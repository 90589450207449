import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import IdentificationCardIcon from "./icons/IdentificationCardIcon";
import DefaultText from "./DefaultText";
import TextLabel from "./TextLabel";
import { User } from "../model/user.model";
import GuardianChipList from "./GuardianChipList";

interface ContactPersonProps {
  name: string;
  email: string;
  guardians: User[];
}

const ContactPerson = ({ name, email, guardians }: ContactPersonProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ float: "right", alignItems: "center" }}>
        <IdentificationCardIcon />
      </Box>
      <Box sx={{ paddingTop: "15px" }}>
        <DefaultText sx={{ fontWeight: "700", paddingBottom: "8px" }}>{t("main-contact-person")}</DefaultText>
        <DefaultText sx={{ paddingBottom: "6px" }}>{name}</DefaultText>
        <DefaultText sx={{ paddingBottom: "24px" }}>{email}</DefaultText>
        <TextLabel sx={{ paddingBottom: "6px" }}>{t("guardians")}</TextLabel>
        <GuardianChipList limit={2} items={guardians} />
      </Box>
    </Box>
  );
};

export default ContactPerson;
