import { SxProps } from "@mui/material";
import RatingScaleQuestionLayout from "./RatingScaleQuestionLayout";
import { QuestionComponentProps } from "./model/ratingScales.types";
import useQuestion from "../hooks/useQuestion";
import useAnswerApi from "../hooks/useAnswerApi";
import { observer } from "mobx-react-lite";
import DefaultTextArea from "./DefaultTextArea";
import { useTranslation } from "react-i18next";
import { useAppState } from "../state/AppState";

const RatingScaleTextAreaQuestion = (props: QuestionComponentProps & { sx?: SxProps }) => {
  const { t } = useTranslation();
  const { questionId } = props;
  const question = useQuestion(questionId);
  const { sendDebouncedAnswer, isLoading } = useAnswerApi(question);
  const { ratingScaleFormState } = useAppState();
  const hasMoreQuestions = ratingScaleFormState.page && ratingScaleFormState.page?.questions.length > 1;
  const { isFormSubmitted } = ratingScaleFormState;

  return (
    <RatingScaleQuestionLayout
      {...props}
      sx={{
        ...props.sx,
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "#F7F8FB",
        margin: 0,
        gap: "16px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        marginLeft: "-32px",
        marginRight: "-32px",
        paddingLeft: "32px",
        paddingRight: "32px",
        marginTop: hasMoreQuestions ? "32px" : 0,
        borderTop: hasMoreQuestions ? "1px solid #D5DBE3" : undefined,
      }}
    >
      <DefaultTextArea
        disabled={isFormSubmitted}
        value={question.textAnswer || ""}
        maxLength={500}
        onChange={async (answer) => {
          await sendDebouncedAnswer(answer);
        }}
        isLoading={isLoading}
        wrapperSx={{ width: "100%" }}
        placeholder={isFormSubmitted ? undefined : t("please-leave-your-comments")}
      />
    </RatingScaleQuestionLayout>
  );
};

export default observer(RatingScaleTextAreaQuestion);
