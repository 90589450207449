import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface WarningIconProps {
  sx?: SxProps;
}

const WarningIcon = ({ sx }: WarningIconProps) => <DefaultIcon src={"warning.svg"} sx={{ width: "16px", height: "16px", ...sx }} />;

export default WarningIcon;
