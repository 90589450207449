import { useEffect } from "react";

export const useClosePageModal = (element: string) => {
  const unloadCallback = (event: { preventDefault: () => void; returnValue: string }) => {
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  useEffect(() => {
    if (element) {
      window.addEventListener("beforeunload", unloadCallback);

      return () => {
        window.removeEventListener("beforeunload", unloadCallback);
      };
    }
  }, [element]);
};
