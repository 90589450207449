import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import { Trans, useTranslation } from "react-i18next";
import TextHeader from "./TextHeader";
import EmailIcon from "./icons/EmailIcon";
import PurpleArrowLeftIcon from "./icons/PurpleArrowLeftIcon";
import { userApi } from "../api/UserApi";
import { getErrorState } from "../state/ErrorState";

interface CheckYourMailProps {
  email: string;
  handleBack: () => void;
}

const CheckYourMail = ({ email, handleBack }: CheckYourMailProps) => {
  const { t } = useTranslation();
  const errorState = getErrorState();

  const resendPassword = async () => {
    const response = await userApi.resetPasswordInit(email);
    if (!response.ok) {
      errorState.code = response.error.code;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", paddingBottom: "10px" }}>
        <EmailIcon />
        <TextHeader sx={{ paddingTop: "4px", paddingLeft: "8px" }}>{t("check-your-email")}</TextHeader>
      </Box>
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>
        <Trans i18nKey="check-email-description" components={{ span: <span style={{ color: "rgba(145, 84, 201, 1)" }}></span> }} values={{ email: email }} />
      </DefaultText>
      <Box sx={{ display: "flex", marginTop: "40px" }}>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", paddingRight: "5px" }}>{t("email-missing")}</DefaultText>
        <DefaultSecondaryButton
          onClick={resendPassword}
          sx={{ border: "none", padding: "0", height: "inherit", textDecorationLine: "underline", "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" } }}
        >
          {t("resend")}
        </DefaultSecondaryButton>
      </Box>
      <DefaultSecondaryButton
        startIcon={<PurpleArrowLeftIcon />}
        onClick={() => {
          handleBack();
        }}
        sx={{ marginTop: "280px", border: "none", padding: "0", height: "inherit", "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" } }}
      >
        {t("back")}
      </DefaultSecondaryButton>
    </>
  );
};

export default CheckYourMail;
