import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import { formatTimestamp } from "../util/date";
import { useTranslation } from "react-i18next";

const RatingScalesFormInfoCard = ({ typeTitle, createdBy, creationTimestamp, sx }: { typeTitle: string; createdBy: string; creationTimestamp: number; sx?: SxProps }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "16px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "8px",
        border: "1px solid rgba(213, 219, 227, 1)",
        ...sx,
      }}
    >
      <DefaultText sx={{ fontWeight: "700", fontSize: "16px", lineHeight: "24px", marginBottom: "4px" }}>{typeTitle}</DefaultText>
      <DefaultText sx={{ fontSize: "12px" }}>
        {t("requested-by")} {createdBy}
      </DefaultText>
      <DefaultText sx={{ fontSize: "12px" }}>
        {t("on")} {formatTimestamp(creationTimestamp, "MM/DD/YYYY")}
      </DefaultText>
    </Box>
  );
};

export default RatingScalesFormInfoCard;
