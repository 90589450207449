import { Box, LinearProgress, linearProgressClasses, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextHeader from "./TextHeader";
import DefaultText from "./DefaultText";

interface LinearLoaderProps {
  header: string;
  text: string;
}

const LinearLoader = ({ header, text }: LinearLoaderProps) => {
  const { t } = useTranslation();
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 4,
    borderRadius: 16,
    width: 200,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "rgba(231, 234, 238, 1)",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "rgba(145, 84, 201, 1)",
    },
  }));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "calc(100vh - 200px)" }}>
      <TextHeader>{t(header)}</TextHeader>
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", paddingTop: "10px", paddingBottom: "24px" }}>{t(text)}</DefaultText>
      <BorderLinearProgress />
    </Box>
  );
};

export default LinearLoader;
