import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";
import { NavigationState } from "./NavigationState";
import { StudentProfilePageState } from "./StudentProfilePageState";
import { UserState } from "./UserState";
import { NotificationState } from "./NotificationState";
import { StudentEventsState } from "./StudentEventsState";
import { DictionaryState } from "./DictionaryState";
import { RatingScaleFormState } from "./RatingScaleFormState";

class AppState {
  readonly user;
  readonly navigation = new NavigationState();
  readonly studentProfilePageState = new StudentProfilePageState();
  readonly ratingScaleFormState = new RatingScaleFormState();
  readonly notification = new NotificationState();
  readonly studentEvents = new StudentEventsState();
  readonly dictionary = new DictionaryState();
  constructor(user: UserState) {
    this.user = user;
    makeAutoObservable(this);
  }
}

const user = new UserState();
export const getUserState = () => user;
export const state = new AppState(user);
export const RootContext = createContext<AppState>(state);
export const useAppState = () => useContext<AppState>(RootContext);
