import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import { InvitationData } from "../model/invitationData.model";
import { Trans, useTranslation } from "react-i18next";
import DefaultInput from "./DefaultInput";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import { useState } from "react";
import TextHeader from "./TextHeader";
import { invitationApi } from "../api/InvitationApi";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../state/AppState";
import { patterns, validate } from "../util/validation";
import { hasErrorKey } from "../api/Api";
import { getErrorState } from "../state/ErrorState";
import ExpiredInvitation from "./ExpiredInvitation";
import { userApi } from "../api/UserApi";

const InvitationCodeValidation = ({ accessData }: { accessData: InvitationData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [invitationCode, setInvitationCode] = useState("");
  const { user, notification } = useAppState();
  const errorState = getErrorState();
  const disabledProceedBtn = invitationCode.length < 1 || loading || errorMsg === t("invitation-request-has-expired");
  const navigate = useNavigate();
  const [expiredInvitation, setExpiredInvitation] = useState<boolean>(false);

  const validateInvitationCode = async (invitationCode: string) => {
    if (user.invitationId && user.invitationToken) {
      return invitationApi.validateInvitationCode(user.invitationId, user.invitationToken, invitationCode);
    } else {
      throw Error("Invitation data does not exist");
    }
  };

  const handleCodeSubmit = async (invitationCode: string) => {
    if (invitationCode.length > 0 && errorMsg !== t("invitation-request-has-expired")) {
      if (invitationCode.length < 6) {
        setErrorMsg(t("incorrect-invitation-code"));
        return;
      }
      setLoading(true);
      const response = await validateInvitationCode(invitationCode);
      if (response.ok) {
        setErrorMsg("");
        user.email = response.data?.email || null;
        user.newStudentFirstName = response.data?.firstName || null;
        user.newStudentLastName = response.data?.lastName || null;
        user.registrationToken = response.data?.registrationToken || null;
        if (user.registrationToken) {
          navigate(`/register/step1${user.invitationUrl()}`);
        } else {
          await handleRegisteredUser();
        }
      } else if (hasErrorKey("invitation.code.attempts.limit.exceeded", response.error)) {
        setErrorMsg(t("invitation-request-has-expired"));
      } else if (hasErrorKey("invitation.code.wrong", response.error)) {
        setErrorMsg(t("incorrect-invitation-code"));
      } else if (response.error.code === 403) {
        setExpiredInvitation(true);
      } else {
        errorState.code = response.error.code;
      }
      setLoading(false);
    }
  };

  const handleRegisteredUser = async () => {
    const meResponse = await userApi.me();
    if (meResponse.ok) {
      //user is logged in
      notification.addNotification({
        title: t("new-student-record"),
        message: (
          <Trans
            i18nKey="access-to-student"
            components={{ b: <b></b> }}
            values={{
              newStudentFirstName: user.newStudentFirstName,
              newStudentLastName: user.newStudentLastName,
            }}
          />
        ) as unknown as string,
      });
      user.clearInvitationData();
      navigate("/");
    } else {
      navigate("/login");
    }
  };

  const handleInputChange = (value: string, setter: (value: string) => void, errorSetter: (value: string) => void) => {
    setter(value);
    const error = validate(value, [patterns.requiredField]);
    if (error) {
      errorSetter(t(error));
    } else {
      errorSetter("");
    }
  };

  if (expiredInvitation) {
    return <ExpiredInvitation />;
  }

  return (
    <>
      <TextHeader sx={{ marginTop: "80px", marginBottom: "57px" }}>{t("new-invitation")}</TextHeader>
      <TextHeader sx={{ fontSize: "21px", lineHeight: "24px" }}>{t("dear-teacher")}</TextHeader>
      <Box sx={{ marginTop: "24px", marginBottom: "32px", width: "394px" }}>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>
          <Trans
            i18nKey="invitation-to-portal"
            components={{ b: <b></b> }}
            values={{
              adminGuardianFirstName: accessData.adminGuardianFirstName,
              adminGuardianLastName: accessData.adminGuardianLastName,
              newStudentFirstName: accessData.firstName,
              newStudentLastName: accessData.lastName,
            }}
          />
        </DefaultText>
      </Box>
      <DefaultInput
        label={t("invitation-code")}
        value={invitationCode}
        maxLength={6}
        onChange={(value) => handleInputChange(value, setInvitationCode, setErrorMsg)}
        onSubmit={handleCodeSubmit}
        error={!!errorMsg}
        errorMsg={errorMsg}
      />
      <DefaultPrimaryButton
        onClick={() => {
          handleCodeSubmit(invitationCode);
        }}
        isLoading={loading}
        disabled={disabledProceedBtn}
        loadingText={t("validating")}
        sx={{ width: "323px", marginTop: "48px" }}
      >
        {t("proceed")}
      </DefaultPrimaryButton>
    </>
  );
};

export default InvitationCodeValidation;
