import { observer } from "mobx-react-lite";
import DefaultButtonGroup from "./DefaultButtonGroup";
import { QuestionComponentProps } from "./model/ratingScales.types";
import useQuestion from "../hooks/useQuestion";
import DefaultSelect, { SelectItem } from "./DefaultSelect";
import { useTranslation } from "react-i18next";
import useAnswerApi from "../hooks/useAnswerApi";
import { useAppState } from "../state/AppState";
import SelectedAnswerChip from "./SelectedAnswerChip";
import SelectedAnswerText from "./SelectedAnswerText";

const QUESTIONS_WITH_DROP_DOWN_ANSWERS = [
  "adhd.vanderbilt.teacher.baseline.group.behavior.01.page.01.medicationinformation",
  "adhd.vanderbilt.teacher.followup.group.behavior.medicationinformation",
  "adhd.vanderbilt.teacher.baseline.group.ticbehaviors.01.question.01",
  "adhd.vanderbilt.teacher.baseline.group.ticbehaviors.01.question.02",
];

const RatingScaleAnswerSelector = ({ questionId }: QuestionComponentProps) => {
  const { t } = useTranslation();
  const question = useQuestion(questionId);
  const { isLoading, sendAnswer } = useAnswerApi(question);
  const { ratingScaleFormState } = useAppState();
  const { isFormSubmitted } = ratingScaleFormState;
  if (isFormSubmitted) {
    const selectedAnswerText: string | undefined = question.options?.find((option) => option.code === question.selectedAnswer)?.text;
    return selectedAnswerText ? (
      QUESTIONS_WITH_DROP_DOWN_ANSWERS.includes(question.code) ? (
        <SelectedAnswerText>{selectedAnswerText}</SelectedAnswerText>
      ) : (
        <SelectedAnswerChip answer={selectedAnswerText} />
      )
    ) : (
      <></>
    );
  }
  const selectItems: SelectItem[] = question.options?.map((option) => ({
    value: option.code,
    label: option.text,
  })) as SelectItem[];
  if (QUESTIONS_WITH_DROP_DOWN_ANSWERS.includes(question.code)) {
    return (
      <DefaultSelect
        placeholder={t("please-choose-one")}
        data={selectItems}
        value={question.selectedAnswer || ""}
        disabled={isLoading}
        isLoading={isLoading}
        selectHandler={async (answer) => {
          if (answer) {
            await sendAnswer(answer);
          }
        }}
        sx={{ width: "300px" }}
        menuItemSx={{ maxWidth: "300px" }}
      />
    );
  }

  return (
    <DefaultButtonGroup
      buttons={selectItems}
      selectedValue={question.selectedAnswer}
      disabled={isLoading}
      loadingValue={isLoading ? question.selectedAnswer : undefined}
      onClick={async (answer: string) => {
        if (answer !== question.selectedAnswer) {
          await sendAnswer(answer);
        }
      }}
    />
  );
};

export default observer(RatingScaleAnswerSelector);
