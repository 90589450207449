import { Box, SxProps } from "@mui/material";
import Link from "./Link";

interface DefaultTableRowProps {
  link?: string;
  children: React.ReactNode;
  sx?: SxProps;
}

const rowSx: SxProps = {
  height: "24px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "4px",
  padding: "10px 16px",
  backgroundColor: "rgba(255, 255, 255, 1)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
  borderRadius: "4px",
  minWidth: "800px",
  "&:hover": {
    backgroundColor: "rgba(231, 234, 238, 1)",
  },
};

const DefaultTableRow = ({ link, children, sx }: DefaultTableRowProps) => {
  if (link) {
    return (
      <Link to={link} sx={{ ...rowSx, cursor: "pointer", ...sx }}>
        {children}
      </Link>
    );
  } else {
    return <Box sx={{ ...rowSx, ...sx }}>{children}</Box>;
  }
};

export default DefaultTableRow;
