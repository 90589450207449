import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import { useTranslation } from "react-i18next";

interface DeleteConfirmationProps {
  handleClose: () => void;
  handleDelete: () => void;
  loading: boolean;
}

const DeleteConfirmation = ({ handleClose, handleDelete, loading }: DeleteConfirmationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>{t("are-you-sure-to-delete")}</DefaultText>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "40px" }}>
        <DefaultSecondaryButton onClick={handleClose} sx={{ width: "160px", height: "44px", marginRight: "20px" }}>
          {t("cancel")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleDelete} disabled={loading} isLoading={loading} sx={{ width: "166px", height: "44px" }}>
          {t("delete")}
        </DefaultPrimaryButton>
      </Box>
    </>
  );
};

export default DeleteConfirmation;
