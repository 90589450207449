import { SxProps } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { Link as ReactRouterLink } from "react-router-dom";

interface LinkProps {
  to: string;
  children: React.ReactNode;
  target?: string;
  sx?: SxProps;
}

const Link = ({ to, children, target, sx }: LinkProps) => {
  return (
    <MuiLink
      component={ReactRouterLink as any}
      to={to}
      target={target}
      sx={{
        fontSize: "14px",
        lineHeight: "16px",
        color: "rgba(78, 93, 105, 1)",
        textDecoration: "none",
        ...sx,
      }}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
