import { makeAutoObservable } from "mobx";

export class ErrorState {
  private _code: number | null = null;
  constructor() {
    makeAutoObservable(this);
  }
  get code(): number | null {
    return this._code;
  }
  set code(value: number | null) {
    this._code = value;
  }
}

const errorState = new ErrorState();

export const getErrorState = () => errorState;
