export enum RatingScalesFormStatus {
  IN_PROGRESS = "IN_PROGRESS",
  REQUESTED = "REQUESTED",
  COMPLETED = "COMPLETED",
  REVOKED = "REVOKED",
}

export interface RatingScalesFormRequest {
  uuid: string;
  typeTitle: string;
  relationTitle: string;
  status: RatingScalesFormStatus;
  createdBy: string;
  creationTimestamp: number;
  submissionTimestamp?: number;
}

export interface RatingScalesFormPageContent {
  sectionName: string;
  completedCount: number;
  totalCount: number;
  directions?: string;
  questions: RatingScalesFormQuestion[];
}

export enum RatingScalesFormQuestionType {
  CODING = "coding",
  STRING = "string",
  TEXT = "text",
}

export interface RatingScalesFormQuestionCondition {
  questionUuid: string;
  answerCode: string;
}

export interface RatingScalesFormAnswerOption {
  code: string;
  text: string;
}
export interface RatingScalesFormQuestion {
  uuid: string;
  code: string;
  type: RatingScalesFormQuestionType;
  required: boolean;
  order?: number;
  text: string;
  explainer?: string;
  options?: RatingScalesFormAnswerOption[];
  textAnswer?: string;
  selectedAnswer?: string;
  dateAnswer?: string;
  integerAnswer?: number;
  enableWhenAny?: RatingScalesFormQuestionCondition[];
}

export interface RatingScalesFormSectionPage {
  uuid: string;
  order: number;
  isCompleted: boolean;
}

export interface RatingScalesFormSection {
  uuid: string;
  order: number;
  name: string;
  completedCount: number;
  totalCount: number;
  pages: RatingScalesFormSectionPage[];
}

export interface RatingScalesFormSummary extends RatingScalesFormRequest {
  completedCount: number;
  totalCount: number;
  sections: RatingScalesFormSection[];
  licenseText: string;
  licenseUrl: string;
  licenseUrlText: string;
}

export interface QuestionsCount {
  completed: number;
  total: number;
}
