import { SxProps, Typography } from "@mui/material";

interface DefaultTextProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const DefaultText = ({ children, sx }: DefaultTextProps) => {
  return <Typography sx={{ fontSize: "14px", color: "rgba(4, 11, 16, 1)", lineHeight: "16px", fontWeight: 400, ...sx }}>{children}</Typography>;
};

export default DefaultText;
