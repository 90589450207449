import DefaultText from "./components/DefaultText";
import { useTranslation } from "react-i18next";
import { Box, SxProps } from "@mui/material";
import Link from "./components/Link";

interface FooterProps {
  privacyPolicy?: boolean;
  sx?: SxProps;
}
const version = process.env.REACT_APP_VERSION;

const Footer = ({ sx, privacyPolicy }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", minWidth: "435px", paddingY: "24px", ...sx }}>
      <DefaultText sx={{ color: "rgba(78, 93, 105, 1)" }}>
        {t("footer")} - {version}
      </DefaultText>
      {privacyPolicy && (
        <Link to="/privacy-policy" target="_blank" sx={{ marginLeft: "36px", color: "rgba(145, 84, 201, 1)", textDecorationLine: "underline" }}>
          {t("privacy-policy")}
        </Link>
      )}
    </Box>
  );
};

export default Footer;
