import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAppState } from "../state/AppState";
import Link from "./Link";
import { useTranslation } from "react-i18next";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import DefaultText from "./DefaultText";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RatingScaleSubmitFormModal from "./RatingScaleSubmitFormModal";
import RatingScaleSubmitFormSuccessModal from "./RatingScaleSubmitFormSuccessModal";

interface RatingScalePageActionButtonsProps {
  pageId: string;
  formId: string;
  studentId: string;
}

const RatingScalePageActionButtons = ({ pageId, formId, studentId }: RatingScalePageActionButtonsProps) => {
  const { t } = useTranslation();
  const { ratingScaleFormState } = useAppState();
  const { isFormSubmitted, allFormQuestionsAnswered, isPageCompleted, loading, switchingPages } = ratingScaleFormState;

  const { previousUuid, nextUuid } = ratingScaleFormState.getPreviousAndNextPageUuids(pageId);
  const [displaySubmitModal, setDisplaySubmitModal] = useState<boolean>(false);
  const [displaySuccessModal, setDisplaySuccessModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const isFinalPageDisplayed = nextUuid === null;
  const canContinue = !loading && !switchingPages && isPageCompleted && !isFinalPageDisplayed;
  const canSubmit = !loading && !switchingPages && allFormQuestionsAnswered && isFinalPageDisplayed;

  const onClickContinue = () => {
    if (canSubmit) {
      setDisplaySubmitModal(true);
    }
  };

  const renderActionButton = () => {
    const button = (
      <DefaultPrimaryButton disabled={!canContinue && !canSubmit} onClick={onClickContinue}>
        {isFinalPageDisplayed ? t(isFormSubmitted ? "close" : "submit") : t("continue")}
      </DefaultPrimaryButton>
    );
    if (isFinalPageDisplayed && isFormSubmitted) {
      return <Link to={`/students/${studentId}/ratingScales/${formId}`}>{button}</Link>;
    } else if (canContinue) {
      return <Link to={`/students/${studentId}/ratingScales/${formId}/pages/${nextUuid}`}>{button}</Link>;
    }
    return button;
  };

  return (
    <>
      <Box sx={{ marginTop: "60px", display: "flex", alignItems: "flex-end", justifyContent: "center", flexDirection: "column" }}>
        {!isPageCompleted && !switchingPages && (
          <Box sx={{ position: "relative", width: "100%" }}>
            <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", position: "absolute", bottom: 16, right: 0 }}>{t("please-answer-all-questions")}</DefaultText>
          </Box>
        )}
        <Box sx={{ display: "flex", gap: "60px", alignItems: "center" }}>
          {previousUuid && !loading && !switchingPages && (
            <Link
              sx={{ display: "flex", alignItems: "center", fontSize: "16px", color: "rgba(145, 84, 201, 1)" }}
              to={`/students/${studentId}/ratingScales/${formId}/pages/${previousUuid}`}
            >
              {t("back")}
            </Link>
          )}
          {renderActionButton()}
        </Box>
      </Box>
      <RatingScaleSubmitFormModal
        formId={formId}
        studentId={studentId}
        isOpen={displaySubmitModal}
        onClose={() => setDisplaySubmitModal(false)}
        onSubmit={() => {
          setDisplaySubmitModal(false);
          setDisplaySuccessModal(true);
        }}
      />
      <RatingScaleSubmitFormSuccessModal isOpen={displaySuccessModal} onClose={() => navigate(`/students/${studentId}/ratingScales`)} />
    </>
  );
};

export default observer(RatingScalePageActionButtons);
