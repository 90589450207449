import { useParams } from "react-router-dom";
import useStudentData from "../hooks/useStudentData";
import RatingScaleFormInformation from "../components/RatingScaleFormInformation";
import { withLoader } from "../hoc/withLoader";
import RatingScalePageQuestions from "../components/RatingScalePageQuestions";
import { Box, CircularProgress, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { ratingScalesApi } from "../api/RatingScalesApi";
import RatingScalePageActionButtons from "../components/RatingScalePageActionButtons";
import { useAppState } from "../state/AppState";
import { observer } from "mobx-react-lite";
import { RatingScalesFormSummary } from "../model/ratingScales.model";
import BackToRatingScalesLink from "../components/BackToRatingScalesLink";

const LoadingRatingScalePageQuestions = withLoader(RatingScalePageQuestions);
const LoadingRatingScaleFormInformation = withLoader(RatingScaleFormInformation);

const RatingScaleFormPage = () => {
  const { ratingScaleId, pageId, id } = useParams();
  const { ratingScaleFormState, navigation } = useAppState();
  const [ratingScalesRequestForm, setRatingScalesRequestForm] = useState<RatingScalesFormSummary | null>(null);

  useStudentData();

  useEffect(() => {
    const getData = async () => {
      window.scroll({ behavior: "smooth", top: 0 });
      const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
      const result = await ratingScalesApi.getRatingScalePageContent(id as string, ratingScaleId as string, pageId as string, userLocale);
      ratingScaleFormState.switchingPages = false;
      if (result.ok) {
        ratingScaleFormState.page = result.data;
      } else {
        ratingScaleFormState.apiError = result.error;
      }
    };
    ratingScaleFormState.switchingPages = true;
    getData();
  }, [id, pageId, ratingScaleFormState, ratingScaleId]);

  useEffect(() => {
    const getData = async () => {
      setRatingScalesRequestForm(null);
      const result = await ratingScalesApi.getRatingScalesRequestFormSummary(id as string, ratingScaleId as string);
      if (result.ok) {
        setRatingScalesRequestForm(result.data);
        ratingScaleFormState.formSummary = result.data;
      }
    };
    getData();
  }, [id, ratingScaleFormState, ratingScaleId]);

  useEffect(() => {
    navigation.scrollRestorationDisabled = true;
    return () => {
      navigation.scrollRestorationDisabled = false;
      ratingScaleFormState.reset();
    };
  }, [navigation, ratingScaleFormState]);

  const { page } = ratingScaleFormState;

  return (
    <>
      <BackToRatingScalesLink />
      <LoadingRatingScaleFormInformation isLoading={ratingScalesRequestForm === null} ratingScalesRequestForm={ratingScalesRequestForm as RatingScalesFormSummary}>
        <Box sx={{ display: "flex", gap: "16px", flexDirection: "column" }}>
          <Box sx={{ display: "flex", height: "30px" }}>
            <Skeleton variant="rounded" sx={{ height: "inherit", flex: 0.2 }} />
          </Box>
          <Box sx={{ display: "flex", gap: "16px", height: 122 }}>
            <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
            <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
          </Box>
        </Box>
      </LoadingRatingScaleFormInformation>
      <LoadingRatingScalePageQuestions isLoading={page === null || ratingScalesRequestForm === null} formId={ratingScaleId as string} studentId={id as string}>
        <Box
          sx={{
            borderRadius: "8px",
            border: "1px solid #D5DBE3",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
            marginTop: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
          }}
        >
          <CircularProgress sx={{ color: "rgba(145, 84, 201, 1)" }} size="40px" />
        </Box>
      </LoadingRatingScalePageQuestions>
      {page && ratingScalesRequestForm && <RatingScalePageActionButtons pageId={pageId as string} formId={ratingScaleId as string} studentId={id as string} />}
    </>
  );
};

export default observer(RatingScaleFormPage);
