import { Box, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultText from "./DefaultText";
import DefaultTooltip from "./DefaultTooltip";
import GuardianChip from "./GuardianChip";
import { User } from "../model/user.model";

interface GuardianChipListProps {
  items: User[];
  limit?: number;
  sx?: SxProps;
}

const GuardianChipList = ({ items, limit, sx }: GuardianChipListProps) => {
  const { t } = useTranslation();
  const itemsLength = items.length;
  const slicedItems = items.slice(0, limit);

  return (
    <Box sx={{ display: "flex", gap: "2px", flexWrap: "wrap", ...sx }}>
      {slicedItems.map((item, index) => (
        <GuardianChip sx={{ maxWidth: "195px" }} user={item} key={index} />
      ))}
      {limit && itemsLength > limit && (
        <DefaultTooltip
          sx={{ maxWidth: "640px" }}
          title={
            <Box sx={{ padding: "16px", display: "flex", gap: "4px", flexWrap: "wrap" }}>
              {items.map((item, index) => (
                <GuardianChip user={item} key={index} />
              ))}
            </Box>
          }
        >
          <DefaultText sx={{ fontSize: "12px", fontWeight: 700, paddingTop: "2px", cursor: "pointer" }}>
            (+ {itemsLength - limit} {t("more")})
          </DefaultText>
        </DefaultTooltip>
      )}
    </Box>
  );
};

export default GuardianChipList;
