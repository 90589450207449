import { SxProps } from "@mui/material";
import DefaultText from "./DefaultText";

interface TextHeaderProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const TextHeader = ({ children, sx }: TextHeaderProps) => {
  return <DefaultText sx={{ fontSize: "28px", lineHeight: "32px", fontWeight: 700, ...sx }}>{children}</DefaultText>;
};

export default TextHeader;
