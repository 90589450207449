import { SxProps, Box } from "@mui/material";
import useQuestion from "../hooks/useQuestion";
import DefaultText from "./DefaultText";
import { QuestionComponentProps } from "./model/ratingScales.types";

const RatingScaleQuestionLayout = ({ questionId, sx, children }: Omit<QuestionComponentProps, "formId"> & { sx?: SxProps; children: React.ReactNode }) => {
  const question = useQuestion(questionId);
  return (
    <Box
      sx={{
        paddingTop: "16px",
        paddingBottom: "16px",
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        alignItems: "center",
        ...sx,
      }}
    >
      <Box>
        <DefaultText sx={{ fontSize: "16px", fontWeight: question.order ? 400 : 700 }}>{`${question.order ? question.order + ". " : ""}${question.text}`}</DefaultText>
        {question.explainer && <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", marginTop: "4px" }}>{question.explainer}</DefaultText>}
      </Box>
      {children}
    </Box>
  );
};

export default RatingScaleQuestionLayout;
