export const withLoader =
  <T,>(WrappedComponent: ({ ...props }: T) => JSX.Element) =>
  // eslint-disable-next-line react/display-name
  ({ isLoading, children, ...props }: { isLoading: boolean; children: React.ReactNode } & T) => {
    if (isLoading) {
      return <>{children}</>;
    } else {
      //@ts-ignore
      return <WrappedComponent {...props} />;
    }
  };
