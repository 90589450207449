import { Button, CircularProgress, SxProps } from "@mui/material";

interface DefaultPrimaryButtonProps {
  children: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: React.ReactNode;
  sx?: SxProps;
}

const DefaultPrimaryButton = ({ children, startIcon, endIcon, onClick, disabled, isLoading, loadingText, sx }: DefaultPrimaryButtonProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      onClick={handleClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      sx={{
        padding: "12px 20px",
        height: "48px",
        borderRadius: "8px ",
        textTransform: "none",
        color: "rgba(255, 255, 255, 1)",
        "&:hover": {
          backgroundColor: "rgba(102, 56, 158, 1)",
        },
        backgroundColor: "rgba(145, 84, 201, 1)",
        display: "flex",
        fontSize: "16px",
        lineHeight: "24px",
        "&.Mui-disabled": {
          background: "rgba(222, 227, 233, 1)",
          color: "rgba(78, 93, 105, 1)",
        },
        minWidth: "1px",
        ...sx,
      }}
    >
      {isLoading ? (
        <>
          <CircularProgress style={{ color: "rgba(112, 124, 136, 1)" }} size="20px" sx={{ mr: "8px" }} /> {loadingText}
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default DefaultPrimaryButton;
