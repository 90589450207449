import { useAppState } from "../state/AppState";
import { Box, Skeleton } from "@mui/material";
import StudentEvent from "./StudentEvent";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState } from "react";
import EmptyEventsList from "./EmptyEventsList";

const EventsHistory = () => {
  const { studentEvents } = useAppState();
  const [numberOfItems, setNumberOfItems] = useState(10);
  const items = studentEvents.list.slice(0, numberOfItems);

  const fetchData = () => {
    setTimeout(() => {
      setNumberOfItems(numberOfItems + 10);
    }, 500);
  };

  return (
    <>
      {studentEvents.list.length > 0 ? (
        <Box
          key="eventsContainer"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "32px",
            gap: "24px",
            backgroundColor: "rgba(247, 248, 251, 1)",
            borderRadius: "8px",
            border: "1px solid rgba(213, 219, 227, 1)",
          }}
        >
          <InfiniteScroll
            dataLength={items.length}
            next={fetchData}
            hasMore={items.length !== studentEvents.list.length}
            loader={
              <Box sx={{ height: 194, paddingTop: "24px" }}>
                <Skeleton variant="rounded" sx={{ height: "inherit" }} />
              </Box>
            }
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              {items.map((event, index) => (
                <StudentEvent key={index} date={event.date} events={event.events} />
              ))}
            </Box>
          </InfiniteScroll>
        </Box>
      ) : (
        <EmptyEventsList />
      )}
    </>
  );
};

export default observer(EventsHistory);
