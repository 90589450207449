import { Box, CircularProgress, IconButton, InputAdornment, SxProps, TextField } from "@mui/material";
import WarningIcon from "./icons/Warning";
import DefaultText from "./DefaultText";
import EyeIcon from "./icons/EyeIcon";
import { useState } from "react";
import EyeSlashIcon from "./icons/EyeSlashIcon";
import { Trans } from "react-i18next";

interface DefaultInputProps {
  value: string;
  onChange?: (value: string) => void;
  label?: string;
  hint?: string;
  disabled?: boolean;
  password?: boolean;
  defaultValue?: string;
  onSubmit?: (value: string) => void;
  error?: boolean;
  errorMsg?: string;
  placeholder?: string;
  maxLength?: number;
  isLoading?: boolean;
  sx?: SxProps;
}

const DefaultInput = ({ value, onChange, label, hint, disabled, password, defaultValue, onSubmit, error, errorMsg, placeholder, maxLength, isLoading, sx }: DefaultInputProps) => {
  const [hidePassword, setHidePassword] = useState(password);

  const handleClickHidePassword = () => setHidePassword((show) => !show);

  const handleMouseHidePassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(value);
    }
  };

  const onBlur = () => {
    if (onChange && !value) {
      onChange("");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isLoading && <CircularProgress style={{ color: "#9154C9" }} size="20px" sx={{ mr: "10px" }} />}
      <Box sx={{ width: "390px", ...sx }}>
        {label && <DefaultText sx={{ paddingBottom: "8px" }}>{label}</DefaultText>}
        <TextField
          value={value}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          type={hidePassword ? "password" : "text"}
          error={error}
          onChange={(e) => onChange && onChange(e.target.value)}
          onBlur={onBlur}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          sx={{
            width: "inherit",
            color: "rgba(4, 11, 16, 1)",
            "& .MuiInputBase-root.Mui-disabled": { backgroundColor: "rgba(222, 227, 233, 1)" },
            "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "rgba(78, 93, 105, 1)" },
          }}
          inputProps={{ maxLength }}
          InputProps={{
            style: { fontSize: "14px", borderRadius: "8px", height: "48px" },
            endAdornment: (
              <>
                {password && (
                  <InputAdornment position="end" sx={{ paddingLeft: "14px" }}>
                    <IconButton aria-label="toggle password visibility" onClick={handleClickHidePassword} onMouseDown={handleMouseHidePassword} edge="start">
                      {hidePassword ? <EyeIcon /> : <EyeSlashIcon />}
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
        {errorMsg && (
          <Box sx={{ paddingTop: "8px", display: "flex" }}>
            <WarningIcon sx={{ paddingRight: "7px" }} />
            <DefaultText sx={{ fontSize: "10px", color: "rgba(221, 49, 49, 1)" }}>
              <Trans i18nKey={errorMsg} />
            </DefaultText>
          </Box>
        )}
        {hint && <DefaultText sx={{ fontSize: "10px", paddingTop: "4px" }}>{hint}</DefaultText>}
      </Box>
    </Box>
  );
};

export default DefaultInput;
