import { ErrorState, getErrorState } from "../state/ErrorState";

enum ErrorType {
  VALIDATION = "VALIDATION",
  AUTH = "AUTH",
  INTERNAL_ERROR = "INTERNAL_ERROR",
}

interface ErrorInfo {
  type: ErrorType;
  message: string;
  key?: string;
  path?: string;
}

interface ErrorResponse {
  errors: ErrorInfo[];
}

export interface ApiError {
  errors: ErrorInfo[];
  code: number;
}

export interface RequestParams {
  requestPayload?: any;
  refreshAT: boolean;
  expectJsonResponse: boolean;
  retry?: boolean;
  showErrorPage: boolean;
  showLoginOnRefreshATFail: boolean;
}

export const withErrorPage = (params?: Partial<RequestParams>) => ({
  refreshAT: true,
  expectJsonResponse: true,
  showErrorPage: true,
  showLoginOnRefreshATFail: true,
  ...params,
});

export const withErrorHandling = (params?: Partial<RequestParams>) => ({
  refreshAT: true,
  expectJsonResponse: true,
  showErrorPage: false,
  showLoginOnRefreshATFail: true,
  ...params,
});

export type ApiResponse<T> = { data: T | null; ok: true; error: null } | { data: null; ok: false; error: ApiError };
export type ApiResponsePromise<T> = Promise<ApiResponse<T>>;

export type ResponseHookFn = (response: Response, requestParams: RequestParams, api: Api) => Promise<void>;

export const hasErrorKey = (key: string, error: ApiError) => error.errors.some((err: ErrorInfo) => err.key === key);

export class Api {
  private errorState: ErrorState = getErrorState();
  private backendUrl = process.env.REACT_APP_BACKEND_URL;
  private responseHooks: ResponseHookFn[] = [];

  public get = async <T>(url: string, params: RequestParams): Promise<ApiResponse<T>> => this.call(url, "GET", params);

  public post = async <T>(url: string, params: RequestParams): Promise<ApiResponse<T>> => this.call(url, "POST", params);

  public put = async <T>(url: string, params: RequestParams): Promise<ApiResponse<T>> => this.call(url, "PUT", params);

  public delete = async <T>(url: string, params: RequestParams): Promise<ApiResponse<T>> => this.call(url, "DELETE", params);

  public useResponseHooks(hooks: ResponseHookFn[]) {
    this.responseHooks.push(...hooks);

    return this;
  }

  private fetch = async (url: string, method: string, params: RequestParams): Promise<Response> => {
    if (!this.backendUrl) {
      this.errorState.code = 500;
      throw new Error("Backend URL not defined");
    }
    const apiUrl = `${this.backendUrl}/${url}`;
    const { requestPayload } = params;
    return fetch(apiUrl, {
      method,
      body: requestPayload ? JSON.stringify(requestPayload) : undefined,
      headers: {
        ...(requestPayload && { "Content-Type": "application/json" }),
      },
      credentials: "include",
    });
  };

  private call = async (url: string, method: string, params: RequestParams): Promise<ApiResponse<any>> => {
    let response;
    try {
      response = await this.fetch(url, method, params);
      await this.executeResponseHooks(response, params);
      if (!response.ok && params.retry) {
        response = await this.fetch(url, method, params);
      }
    } catch (err) {
      this.errorState.code = 500;
      throw new Error("Network error while calling API");
    }
    return this.handleResponse(response, params);
  };

  private handleResponse = async (response: Response, params: RequestParams): Promise<ApiResponse<any>> => {
    const { expectJsonResponse } = params;
    if (response.ok) {
      const data: any = expectJsonResponse ? await this.getJson(response) : null;
      return {
        ok: true,
        data,
        error: null,
      };
    } else {
      return this.handleError(response, params);
    }
  };

  private getJson = async (response: Response): Promise<any> => {
    try {
      const json = await response.json();
      return json;
    } catch (error) {
      this.errorState.code = 500;
      throw new Error("Error while parsing JSON response");
    }
  };

  private handleError = async (response: Response, params: RequestParams): Promise<ApiResponse<null>> => {
    if (params.showErrorPage) {
      this.errorState.code = response.status;
      return this.createErrorResponse(response.status);
    }
    let errorResponse: ErrorResponse;
    try {
      errorResponse = await response.json();
    } catch (error) {
      return this.createErrorResponse(response.status);
    }
    return this.createErrorResponse(response.status, errorResponse);
  };

  private createErrorResponse = (code: number, errorResponse?: ErrorResponse): ApiResponse<null> => {
    return {
      ok: false,
      data: null,
      error: {
        errors: errorResponse ? errorResponse.errors : [],
        code,
      },
    };
  };

  private executeResponseHooks = async (response: Response, requestParams: RequestParams) => {
    for await (const responseHook of this.responseHooks) {
      await responseHook(response, requestParams, this);
    }
  };
}
