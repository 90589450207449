import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface InfoIconProps {
  sx?: SxProps;
}

const InfoIcon = ({ sx }: InfoIconProps) => <DefaultIcon src={"info.svg"} sx={{ width: "13px", height: "13px", ...sx }} />;

export default InfoIcon;
