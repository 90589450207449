import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../util/date";
import { useEffect, useState } from "react";
import DefaultTableRow from "./DefaultTableRow";
import EmptyRatingScalesList from "./EmptyRatingScalesList";
import { RatingScalesFormStatus, RatingScalesFormRequest } from "../model/ratingScales.model";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import FileTextIcon from "./icons/FileTextIcon";
import Link from "./Link";

export const RECORDS_PER_PAGE = 10;

interface RatingScalesRequestsListProps {
  requests: RatingScalesFormRequest[];
}

const RatingScalesRequestsList = ({ requests }: RatingScalesRequestsListProps) => {
  const { t } = useTranslation();
  const [requestsToDisplay, setRequestsToDisplay] = useState<RatingScalesFormRequest[]>([]);

  const handleRequestsToDisplay = () => {
    setRequestsToDisplay([]);
    const start = requestsToDisplay.length;
    const end = start + RECORDS_PER_PAGE;
    const newRequestsToDisplay = requests.slice(start, end);
    setRequestsToDisplay([...requestsToDisplay, ...newRequestsToDisplay]);
  };

  useEffect(() => {
    handleRequestsToDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderActionButton = (request: RatingScalesFormRequest): React.ReactNode => {
    const buttonSx: SxProps = { height: "28px", fontSize: "12px", padding: "6px 12px", borderRadius: "4px", minWidth: "63px" };
    switch (request.status) {
      case RatingScalesFormStatus.COMPLETED:
        return (
          <DefaultPrimaryButton
            sx={{
              ...buttonSx,
              backgroundColor: "rgba(255, 255, 255, 1)",
              color: "rgba(145, 84, 201, 1)",
              border: "1px solid #9154C9",
              "&:hover": {
                backgroundColor: "rgba(232, 229, 255, 1)",
              },
            }}
          >
            {t("view")}
          </DefaultPrimaryButton>
        );
      case RatingScalesFormStatus.IN_PROGRESS:
        return <DefaultPrimaryButton sx={buttonSx}>{t("continue")}</DefaultPrimaryButton>;
      case RatingScalesFormStatus.REQUESTED:
        return <DefaultPrimaryButton sx={buttonSx}>{t("fill")}</DefaultPrimaryButton>;
      default:
        return <></>;
    }
  };

  return (
    <>
      {(requests.length > 0 || requestsToDisplay.length > 0) && (
        <>
          <Box sx={{ display: "flex", direction: "row", marginBottom: "4px", padding: "12px 16px", gap: "28px", minWidth: "1000px" }}>
            <Box sx={{ flex: 2, minWidth: "160px" }}>
              <DefaultText sx={{ marginLeft: "48px" }}>{t("form-type")}</DefaultText>
            </Box>
            <DefaultText sx={{ flex: 1, minWidth: "112px" }}>{t("requested-by")}</DefaultText>
            <DefaultText sx={{ flex: 1, minWidth: "100px" }}>{t("date-requested")}</DefaultText>
            <DefaultText sx={{ flex: 1, minWidth: "100px" }}>{t("date-completed")}</DefaultText>
            <DefaultText sx={{ flex: 2, minWidth: "160px" }}>{t("status")}</DefaultText>
          </Box>
          {requestsToDisplay.map((request, index) => (
            <DefaultTableRow key={index} sx={{ minWidth: "1000px", gap: "28px", borderRadius: "8px" }}>
              <Box sx={{ flex: 2, minWidth: "160px", display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    backgroundColor: "rgba(231, 234, 238, 1)",
                    width: "48px",
                    minWidth: "48px",
                    height: "44px",
                    marginRight: "16px",
                    marginLeft: "-16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px 0px 0px 8px",
                  }}
                >
                  <FileTextIcon />
                </Box>
                <Box sx={{ minWidth: "112px" }}>
                  <DefaultText sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", direction: "rtl" }}>{request.typeTitle}</DefaultText>
                </Box>
              </Box>
              <Box sx={{ flex: 1, minWidth: "112px", display: "flex", gap: "2px" }}>
                <DefaultText sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{request.createdBy}</DefaultText>
              </Box>
              <Box sx={{ flex: 1, minWidth: "100px" }}>
                <DefaultText>{formatTimestamp(request.creationTimestamp, "MM/DD/YYYY")}</DefaultText>
              </Box>
              <Box sx={{ flex: 1, minWidth: "100px" }}>
                <DefaultText>{request.submissionTimestamp ? formatTimestamp(request.submissionTimestamp, "MM/DD/YYYY") : "-"}</DefaultText>
              </Box>
              <Box sx={{ flex: 2, minWidth: "160px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex" }}>
                  <DefaultText sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>{t(`rating-scales-status.${request.status}`)}</DefaultText>
                </Box>
                <Link to={`${request.uuid}`}>{renderActionButton(request)}</Link>
              </Box>
            </DefaultTableRow>
          ))}
          {requestsToDisplay.length < requests.length && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", minWidth: "1020px" }}>
              <Box onClick={() => handleRequestsToDisplay()}>
                <DefaultText sx={{ marginTop: "10px", fontWeight: 700, color: "rgba(145, 84, 201, 1)", cursor: "pointer" }}>{t("show-more")}...</DefaultText>
              </Box>
            </Box>
          )}
        </>
      )}
      {requests.length === 0 && requestsToDisplay.length === 0 && <EmptyRatingScalesList />}
    </>
  );
};

export default RatingScalesRequestsList;
