import { SxProps } from "@mui/material";
import DefaultChip from "./DefaultChip";
import { TranslatedDictionaryEventType } from "../model/dictionary.model";
import { EventCategory } from "../model/event.model";

interface EventChipProps {
  event: TranslatedDictionaryEventType;
  selected?: boolean;
  sx?: SxProps;
}

const EventChip = ({ event, selected = true, sx }: EventChipProps) => {
  return (
    <DefaultChip
      text={event.text}
      sx={{
        backgroundColor: `${selected ? (event.eventCategory === EventCategory.GOOD_DAY ? "rgba(194, 255, 189, 1)" : "rgba(248, 204, 201, 1)") : "rgba(255, 255, 255, 1)"}`,
        border: `${
          selected ? (event.eventCategory === EventCategory.GOOD_DAY ? "1px solid rgba(194, 255, 189, 1)" : "1px solid rgba(248, 204, 201, 1)") : "1px solid rgba(179, 187, 196, 1)"
        }`,
        borderRadius: "32px",
        padding: "14px 6px",
        ...sx,
      }}
    />
  );
};

export default EventChip;
