import { Route, Routes } from "react-router-dom";
import { Student } from "../model/student.model";
import { withLoader } from "../hoc/withLoader";
import { Box, Skeleton } from "@mui/material";
import StudentProfile from "../components/StudentProfile";
import StudentProfileTabs from "../components/StudentProfileTabs";
import EventsHistoryLoader from "../components/EventsHistoryLoader";
import useStudentData from "../hooks/useStudentData";
import RatingScalesRequestsTabRouter from "../components/RatingScalesRequestsTabRouter";

const LoadingStudentProfile = withLoader(StudentProfile);

const StudentProfilePage = () => {
  const student: Student | null = useStudentData();

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ backgroundColor: "rgba(240, 244, 250, 1)", width: "100vw", height: "380px", position: "absolute", right: "calc(-5vw + 1px)", marginTop: "-25px" }} />
      <Box sx={{ position: "relative" }}>
        <LoadingStudentProfile student={student as Student} isLoading={student === null}>
          <Box sx={{ height: 225, flex: 1, display: "flex", gap: "40px" }}>
            <Skeleton variant="rounded" sx={{ height: "inherit", flex: 0.8 }} />
            <Skeleton variant="rounded" sx={{ height: "inherit", flex: 0.2 }} />
          </Box>
        </LoadingStudentProfile>
        <StudentProfileTabs sx={{ paddingTop: "24px", marginBottom: "39px" }} />
        <Routes>
          <Route path="" element={<EventsHistoryLoader />} />
          <Route path="/ratingScales/*" element={<RatingScalesRequestsTabRouter />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default StudentProfilePage;
