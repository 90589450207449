import { Chip, SxProps } from "@mui/material";

interface DefaultChipProps {
  text: string;
  sx?: SxProps;
}

const DefaultChip = ({ text, sx }: DefaultChipProps) => {
  return (
    <Chip
      label={text}
      sx={{
        fontSize: "12px",
        height: "32px",
        borderRadius: "8px",
        backgroundColor: "rgba(231, 234, 238, 1)",
        ...sx,
      }}
    />
  );
};

export default DefaultChip;
