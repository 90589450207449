import { SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import React from "react";

interface TextLabelProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const TextLabel = ({ children, sx }: TextLabelProps) => {
  return <DefaultText sx={{ fontSize: "12px", color: "rgba(112, 124, 136, 1)", ...sx }}>{children}</DefaultText>;
};

export default TextLabel;
