import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import { useTranslation } from "react-i18next";

interface LeaveConfirmationProps {
  handleClose: () => void;
  handleLeave: () => void;
}

const LeaveConfirmation = ({ handleClose, handleLeave }: LeaveConfirmationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>{t("leaving-the-page-description")}</DefaultText>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "40px" }}>
        <DefaultSecondaryButton onClick={handleLeave} sx={{ height: "44px", marginRight: "20px" }}>
          {t("leave-without-saving")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleClose} sx={{ width: "167px", height: "44px" }}>
          {t("stay")}
        </DefaultPrimaryButton>
      </Box>
    </>
  );
};

export default LeaveConfirmation;
