import { useState } from "react";
import { QuestionsCount, RatingScalesFormQuestion } from "../model/ratingScales.model";
import { useAppState } from "../state/AppState";
import { useDebouncedCallback } from "use-debounce";
import { ratingScalesApi } from "../api/RatingScalesApi";
import { useParams } from "react-router-dom";

interface UseAnswerApiResult {
  sendDebouncedAnswer: (answer: string) => Promise<void>;
  sendAnswer: (answer: string) => Promise<void>;
  isLoading: boolean;
}

export default (question: RatingScalesFormQuestion): UseAnswerApiResult => {
  const { ratingScaleFormState } = useAppState();
  const { page } = ratingScaleFormState;
  const [loading, setLoading] = useState<boolean>(false);
  const { ratingScaleId, id } = useParams();
  if (!ratingScaleId || !id) {
    throw new Error("Student ID or Form ID does not exist");
  }
  if (!page) {
    throw new Error("Form Page not found in app state");
  }

  const callApi = async (answer: string, updateState?: boolean) => {
    if (updateState) {
      ratingScaleFormState.updateAnswer(question, answer);
    }
    const currentSyncedAnswer = ratingScaleFormState.getSyncedAnswer(question.uuid);
    if (currentSyncedAnswer == answer) {
      return;
    }
    setLoading(true);
    ratingScaleFormState.loading++;
    let response;
    if (currentSyncedAnswer) {
      response = await ratingScalesApi.updateAnswer(id, ratingScaleId, question.uuid, answer);
    } else {
      response = await ratingScalesApi.createAnswer(id, ratingScaleId, question.uuid, answer);
    }
    setLoading(false);
    ratingScaleFormState.loading--;
    if (response.ok) {
      ratingScaleFormState.updateSyncedAnswer(question.uuid, answer);
      ratingScaleFormState.updateQuestionsCount(response.data as QuestionsCount);
    } else {
      ratingScaleFormState.apiError = response.error;
    }
  };

  const callApiIfNotLoading = async (answer: string): Promise<void> => {
    if (!loading) {
      await callApi(answer);
    } else {
      await debounceAnswer(answer);
    }
  };

  const debounceAnswer = useDebouncedCallback(callApiIfNotLoading, 500, { maxWait: 2000 });

  const sendDebouncedAnswer = async (answer: string) => {
    ratingScaleFormState.updateAnswer(question, answer);
    await debounceAnswer(answer);
  };

  const sendAnswer = (answer: string) => callApi(answer, true);

  return {
    sendAnswer,
    sendDebouncedAnswer,
    isLoading: loading,
  };
};
