import { Box, SxProps, Tooltip } from "@mui/material";
import React from "react";

interface DefaultTooltipProps {
  children: React.ReactNode;
  title: React.ReactNode;
  placement?: "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top";
  sx?: SxProps;
}

const DefaultTooltip = ({ children, title, placement = "right", sx }: DefaultTooltipProps) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "rgba(255, 255, 255, 1)",
            color: "rgb(78, 93, 105, 1)",
            border: "1px solid #E7EAEE",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
            ...sx,
          },
        },
      }}
      title={title}
      placement={placement}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};

export default DefaultTooltip;
