import { Box, CircularProgress } from "@mui/material";
import { RatingScalesFormPageContent } from "../model/ratingScales.model";
import { useAppState } from "../state/AppState";
import DefaultText from "./DefaultText";
import { Trans, useTranslation } from "react-i18next";
import InfoIcon from "./icons/InfoIcon";
import RatingScaleQuestion from "./RatingScaleQuestion";
import { observer } from "mobx-react-lite";
import RatingScaleErrorModal from "./RatingScaleErrorModal";

interface RatingScalePageQuestionsProps {
  formId: string;
  studentId: string;
}

const RatingScalePageQuestions = ({ formId, studentId }: RatingScalePageQuestionsProps) => {
  const { ratingScaleFormState } = useAppState();
  const { t } = useTranslation();
  const page = ratingScaleFormState.page as RatingScalesFormPageContent;
  const { firstQuestionNumber, lastQuestionNumber, switchingPages } = ratingScaleFormState;
  return (
    <>
      <Box
        sx={{
          borderRadius: "8px",
          border: "1px solid #D5DBE3",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
          marginTop: "60px",
          ...(switchingPages ? { display: "flex", justifyContent: "center", alignItems: "center" } : {}),
        }}
      >
        {switchingPages && <CircularProgress sx={{ color: "rgba(145, 84, 201, 1)", position: "absolute" }} size="40px" />}
        <Box sx={{ visibility: switchingPages ? "hidden" : undefined }}>
          <Box sx={{ borderBottom: "1px solid #D5DBE3", paddingLeft: "32px", paddingTop: "24px", paddingBottom: "16px" }}>
            <DefaultText sx={{ fontSize: "21px", fontWeight: 700 }}>{page.sectionName}</DefaultText>
            {firstQuestionNumber && lastQuestionNumber && (
              <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", marginTop: "8px" }}>
                <Trans i18nKey="questions-range" values={{ firstQuestionNumber, lastQuestionNumber }} />
              </DefaultText>
            )}
          </Box>
          {page.directions && (
            <Box sx={{ borderBottom: "1px solid #D5DBE3", padding: "32px", display: "flex", backgroundColor: "#F7F8FB", alignItems: "center" }}>
              <InfoIcon sx={{ width: "60px", height: "60px" }} />
              <Box sx={{ marginLeft: "20px", display: "flex", flexDirection: "column", gap: "5px" }}>
                <DefaultText sx={{ fontSize: "18px", fontWeight: 700 }}>{t("directions")}</DefaultText>
                <DefaultText sx={{ fontSize: "16px" }}>{page.directions}</DefaultText>
              </Box>
            </Box>
          )}
          <Box sx={{ paddingLeft: "32px", paddingRight: "32px" }}>
            {page.questions.map((question, index) => (
              <RatingScaleQuestion
                questionId={question.uuid}
                key={question.uuid}
                formId={formId}
                sx={{ borderBottom: index !== page.questions.length - 1 ? "1px solid #D5DBE3" : undefined }}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <RatingScaleErrorModal studentId={studentId} />
    </>
  );
};

export default observer(RatingScalePageQuestions);
