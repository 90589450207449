import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";
import LinkBreakIcon from "./icons/LinkBreakIcon";
import { useEffect } from "react";
import { useAppState } from "../state/AppState";
import Link from "./Link";

const ExpiredInvitation = () => {
  const { t } = useTranslation();
  const { user } = useAppState();

  useEffect(() => {
    user.clearInvitationData();
  }, [user]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "760px", paddingTop: "80px" }}>
      <Box>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <LinkBreakIcon />
          <TextHeader sx={{ paddingBottom: "57px" }}>{t("your-invitation-has-expired")}</TextHeader>
        </Box>
        <TextHeader sx={{ fontSize: "21px", lineHeight: "24px" }}>{t("dear-teacher")}</TextHeader>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", paddingY: "24px" }}>{t("unavailable-invitation")}</DefaultText>
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>{t("person-who-shared")}</DefaultText>
      </Box>
      <Box sx={{ display: "flex", paddingBottom: "48px" }}>
        <DefaultText sx={{ color: "rgba(0, 0, 0, 1)", zIndex: 1 }}>{t("already-registered")}?</DefaultText>
        <Link to="/login" sx={{ color: "rgba(145, 84, 201, 1)", zIndex: 1, textDecorationLine: "underline", paddingLeft: "5px" }}>
          {t("sign-in")}
        </Link>
      </Box>
    </Box>
  );
};

export default ExpiredInvitation;
