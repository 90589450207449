import { withLoader } from "../hoc/withLoader";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RatingScalesFormSummary } from "../model/ratingScales.model";
import { ratingScalesApi } from "../api/RatingScalesApi";
import { Box, Skeleton } from "@mui/material";
import RatingScalesRequestsFormInfo from "./RatingScalesRequestsFormInfo";

const LoadingRatingScalesRequestFormInfo = withLoader(RatingScalesRequestsFormInfo);

const RatingScalesRequestsFormInfoContainer = () => {
  const { ratingScaleId, id } = useParams();
  const [ratingScalesRequestForm, setRatingScalesRequestForm] = useState<RatingScalesFormSummary | null>(null);
  useEffect(() => {
    const getData = async () => {
      setRatingScalesRequestForm((await ratingScalesApi.getRatingScalesRequestFormSummary(id as string, ratingScaleId as string)).data);
    };
    getData();
  }, [id, ratingScaleId]);
  return (
    <LoadingRatingScalesRequestFormInfo ratingScalesRequestForm={ratingScalesRequestForm as RatingScalesFormSummary} isLoading={ratingScalesRequestForm === null}>
      <Box sx={{ display: "flex", flexDirection: "column", minWidth: "1000px" }}>
        <Box sx={{ height: 20, minWidth: "1000px", marginBottom: "32px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", width: "165px" }} />
        </Box>
        <Box sx={{ height: 174, paddingTop: "2px", paddingBottom: "6px", marginBottom: "16px", minWidth: "1000px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit" }} />
        </Box>
        <Box sx={{ display: "flex", height: 24, minWidth: "1000px" }}>
          <Skeleton variant="rounded" sx={{ height: "inherit", width: "365px" }} />
          <Skeleton variant="rounded" sx={{ height: "inherit", width: "165px", marginLeft: "auto" }} />
        </Box>
      </Box>
    </LoadingRatingScalesRequestFormInfo>
  );
};

export default RatingScalesRequestsFormInfoContainer;
