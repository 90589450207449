import DefaultText from "./DefaultText";

interface SelectedAnswerTextProps {
  children?: string;
}

const SelectedAnswerText = ({ children }: SelectedAnswerTextProps) => {
  return <DefaultText sx={{ fontSize: "16px", fontWeight: 700, textAlign: "right" }}>{children || ""}</DefaultText>;
};

export default SelectedAnswerText;
