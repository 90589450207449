import { Box, SxProps, Tab, Tabs } from "@mui/material";
import Link from "./Link";

interface DefaultTabsProps {
  data: TabData[];
  onChange: (tabIndex: number) => void;
  value?: number | null;
  sx?: SxProps;
}

interface TabData {
  value?: number;
  label: string;
  link: string;
}

const DefaultTabs = ({ data, onChange, value, sx }: DefaultTabsProps) => {
  const handleTabChange = (_event: React.SyntheticEvent, tabIndex: number) => {
    if (onChange) {
      onChange(tabIndex);
    }
  };

  return (
    <Box sx={{ color: "rgba(4, 11, 16, 1)", marginBottom: "-1px" }}>
      <Tabs
        value={value}
        onChange={handleTabChange}
        textColor={"inherit"}
        aria-label="Tab navigation"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        sx={{
          "& .Mui-selected": {
            color: "rgba(145, 84, 201, 1)",
            backgroundColor: "rgba(249, 249, 249, 1)",
            border: "1px solid rgba(180, 116, 237, 1)",
            borderBottom: "1px solid rgba(249, 249, 249, 1)",
            height: "60px",
          },
          ...sx,
        }}
      >
        {data.map((tab) => {
          return (
            <Tab
              value={tab.value}
              label={
                <Link
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "21px",
                    fontWeight: 700,
                    textTransform: "capitalize",
                    width: "306px",
                    color: "inherit",
                    opacity: "1",
                    padding: "12px 16px",
                  }}
                  to={tab.link}
                >
                  {tab.label}
                </Link>
              }
              key={tab.label}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                padding: "0px",
                opacity: "1",
                marginRight: "8.5px",
                border: "1px solid #D5DBE3",
                borderRadius: "8px 8px 0px 0px",
                borderBottom: "1px solid rgba(180, 116, 237, 1)",
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default DefaultTabs;
