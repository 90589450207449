import { Box } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import TextHeader from "../components/TextHeader";
import DefaultText from "../components/DefaultText";
import { useEffect, useState } from "react";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../state/AppState";
import { userApi } from "../api/UserApi";
import Link from "../components/Link";
import DefaultInput from "../components/DefaultInput";
import { patterns, validate } from "../util/validation";
import { getErrorState } from "../state/ErrorState";
import { useLocation } from "react-router";

const LoginPage = () => {
  const errorState = getErrorState();
  const { t } = useTranslation();
  const { user, notification } = useAppState();
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState(user.email || "");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const onEmailChange = (value: string) => {
    setEmail(value);
    const error = validate(value, [patterns.requiredField]);
    if (error) {
      setEmailErrorMsg(t(error));
    } else {
      setEmailErrorMsg("");
    }
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);
    const error = validate(value, [patterns.requiredField]);
    if (error) {
      setPasswordErrorMsg(t(error));
    } else {
      setPasswordErrorMsg("");
    }
  };

  const handleLoginSubmit = async () => {
    if (email && password && !emailErrorMsg && !passwordErrorMsg) {
      setLoading(true);
      const response = await userApi.login(email, password);
      if (response.ok) {
        if (user.email && user.newStudentFirstName) {
          notification.addNotification({
            title: t("new-student-record"),
            message: (
              <Trans
                i18nKey="access-to-student"
                components={{ b: <b></b> }}
                values={{
                  newStudentFirstName: user.newStudentFirstName,
                  newStudentLastName: user.newStudentLastName,
                }}
              />
            ) as unknown as string,
          });
        }
        user.clearInvitationData();
        setErrorMsg("");
        const searchParams = new URLSearchParams(search);
        const navigateTo = decodeURIComponent(searchParams.get("navigateTo") || "");
        navigate(navigateTo || "/students");
      } else if (response.error.code === 401) {
        setErrorMsg(t("email-or-password-incorrect"));
      } else {
        errorState.code = response.error.code;
      }
      setLoading(false);
    } else {
      if (!email) {
        setEmailErrorMsg(t("mandatory-field"));
      }
      if (!password) {
        setPasswordErrorMsg(t("mandatory-field"));
      }
    }
  };

  useEffect(() => {
    notification.clearNotifications();
  });

  return (
    <Box sx={{ paddingTop: "160px" }}>
      <TextHeader sx={{ paddingBottom: "8px" }}>{t("get-started-now")}</TextHeader>
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>{t("enter-credentials-to-access")}</DefaultText>
      <DefaultInput
        label={t("email")}
        value={email}
        onChange={onEmailChange}
        placeholder={t("enter-email")}
        disabled={!!user.email}
        error={!!emailErrorMsg || !!errorMsg}
        errorMsg={emailErrorMsg}
        onSubmit={handleLoginSubmit}
        sx={{ marginY: "40px" }}
      />
      <DefaultInput
        label={t("password")}
        password
        value={password}
        onChange={onPasswordChange}
        placeholder={t("enter-password")}
        error={!!passwordErrorMsg || !!errorMsg}
        errorMsg={passwordErrorMsg || errorMsg}
        onSubmit={handleLoginSubmit}
        sx={{ paddingBottom: "32px" }}
      />
      <Link to={"/forgot-password"} sx={{ color: "rgba(145, 84, 201, 1)" }}>
        {t("forgot-password")}
      </Link>
      <DefaultPrimaryButton
        onClick={handleLoginSubmit}
        isLoading={loading}
        disabled={email.length < 1 || password.length < 1 || loading}
        loadingText={t("login")}
        sx={{ width: "323px", marginTop: "48px" }}
      >
        {t("login")}
      </DefaultPrimaryButton>
    </Box>
  );
};

export default LoginPage;
