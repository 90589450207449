import { EventCategory } from "./event.model";

export interface Dictionary {
  lastModifiedTimestamp: number;
  locale: string;
  eventTypes: TranslatedDictionaryEventType[];
  hcpEventGroups: TranslationItem[];
}

export interface DictionaryKeyItem {
  key: string;
}

export interface DictionaryEventType extends DictionaryKeyItem {
  eventCategory: EventCategory;
  hcpEventGroup: string;
  components: Component[];
}

export interface TranslatedDictionaryEventType extends DictionaryEventType {
  text: string;
}

export interface TranslationItem extends DictionaryKeyItem {
  text: string;
}

export enum Component {
  TEACHER_WEB_APP = "teacher_web_app",
  GUARDIAN_MOBILE_APP = "guardian_mobile_app",
}

export enum DictionaryType {
  EVENT_TYPE = "eventType",
}
