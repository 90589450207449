import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { userApi } from "./api/UserApi";
import { useAppState } from "./state/AppState";

const AuthGuard = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useAppState();
  const { pathname, search } = useLocation();

  useEffect(() => {
    (async () => {
      const meResponse = await userApi.me();
      user.isAuthorized = meResponse.ok;
      setLoading(false);
    })();
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress sx={{ color: "rgba(145, 84, 201, 1)" }} size="56px" />
      </Box>
    );
  }

  return user.isAuthorized ? <Outlet /> : <Navigate to={`/login?navigateTo=${encodeURIComponent(pathname + search)}`} />;
};

export default AuthGuard;
