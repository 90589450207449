import { makeAutoObservable } from "mobx";

export interface Notification {
  message: string;
  title?: string;
}

export class NotificationState {
  private _list: Notification[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get list() {
    return this._list;
  }

  set list(value: Notification[]) {
    this._list = value;
  }

  addNotification = (notification: Notification) => {
    this.list.push(notification);
  };

  removeNotification = (notification: Notification) => {
    const index = this.list.findIndex((n) => n === notification);
    if (index !== -1) {
      this.list.splice(index, 1);
    }
  };

  clearNotifications = () => {
    this.list = [];
  };
}
