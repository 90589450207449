import { useNavigate } from "react-router-dom";
import { userApi } from "../api/UserApi";
import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppState } from "../state/AppState";

interface LogoutConfirmationProps {
  handleClose: () => void;
}

const LogoutConfirmation = ({ handleClose }: LogoutConfirmationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAppState();

  const handleLogout = async () => {
    setLoading(true);
    await userApi.logout();
    user.isAuthorized = false;
    setLoading(false);
    navigate("/login");
  };

  return (
    <>
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>{t("logout.are-you-sure")}</DefaultText>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "40px" }}>
        <DefaultSecondaryButton onClick={handleClose} sx={{ width: "160px", height: "44px", marginRight: "20px" }}>
          {t("cancel")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleLogout} sx={{ width: "166px", height: "44px" }} isLoading={loading} disabled={loading}>
          {t("logout")}
        </DefaultPrimaryButton>
      </Box>
    </>
  );
};

export default LogoutConfirmation;
