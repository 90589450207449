import { Box, SxProps } from "@mui/material";
import logoImg from "../assets/img/logo.svg";

interface LogoProps {
  sx?: SxProps;
}

const Logo = ({ sx }: LogoProps) => <Box component="img" src={logoImg} sx={{ width: "115px", height: "24px", ...sx }} />;

export default Logo;
