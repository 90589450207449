import { Box } from "@mui/material";
import DefaultText from "../components/DefaultText";
import TextHeader from "../components/TextHeader";
import { useTranslation } from "react-i18next";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../state/AppState";

const DeclinedTermsConditionsPage = () => {
  const { t } = useTranslation();
  const { user } = useAppState();
  const navigate = useNavigate();

  const startAgain = () => {
    navigate(`/register/step1${user.invitationUrl()}`);
  };

  return (
    <Box sx={{ paddingTop: "80px" }}>
      <TextHeader>{t("terms-declined")}</TextHeader>
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", paddingY: "40px" }}>{t("need-to-accept")}</DefaultText>
      <DefaultPrimaryButton onClick={startAgain} sx={{ width: "240px" }}>
        {t("start-again")}
      </DefaultPrimaryButton>
    </Box>
  );
};

export default observer(DeclinedTermsConditionsPage);
