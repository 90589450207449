import { RatingScalesFormSection } from "../model/ratingScales.model";
import { useParams } from "react-router-dom";
import DefaultText from "./DefaultText";
import CheckPurpleIcon from "./icons/CheckPurpleIcon";
import DefaultTooltip from "./DefaultTooltip";
import { Box } from "@mui/material";
import ChevronRightGreyIcon from "./icons/ChevronRightGreyIcon";
import ChevronRightBlackIcon from "./icons/ChevronRightBlackIcon";
import Link from "./Link";

const RatingScaleFormInfoSectionItem = ({ section, disabled = false }: { section: RatingScalesFormSection; disabled?: boolean }) => {
  const { id, ratingScaleId } = useParams();
  const color = disabled ? "#929CA6" : "#040B10";
  const getCurrentPageUuid = (section: RatingScalesFormSection): string | undefined => {
    if (section.completedCount === section.totalCount || section.completedCount === 0) {
      return section.pages[0].uuid;
    }
    const currentPage = section.pages.find((page) => !page.isCompleted);

    return currentPage?.uuid;
  };
  const sectionContent = (
    <>
      <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", marginRight: "16px", color }}>{section.order}.</DefaultText>
      {disabled ? null : <CheckPurpleIcon sx={{ marginRight: "10px" }} />}
      <DefaultTooltip
        title={
          <Box sx={{ padding: "10px" }}>
            <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>{section.name}</DefaultText>
          </Box>
        }
      >
        <DefaultText sx={{ fontSize: "16px", lineHeight: "24px", overflow: "hidden", maxWidth: "237px", textOverflow: "ellipsis", whiteSpace: "nowrap", color }}>
          {section.name}
        </DefaultText>
      </DefaultTooltip>
      {disabled ? <ChevronRightGreyIcon sx={{ marginLeft: "auto" }} /> : <ChevronRightBlackIcon sx={{ marginLeft: "auto" }} />}
    </>
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "inherit",
        height: "28px",
        width: "328px",
        marginRight: "48px",
        marginBottom: "12px",
        alignItems: "center",
        borderBottom: "1px solid rgba(231, 234, 238, 1)",
      }}
    >
      {disabled ? (
        sectionContent
      ) : (
        <Link to={`/students/${id}/ratingScales/${ratingScaleId}/pages/${getCurrentPageUuid(section)}`} sx={{ display: "flex", width: "100%" }}>
          {sectionContent}
        </Link>
      )}
    </Box>
  );
};

export default RatingScaleFormInfoSectionItem;
