import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface FileTextIconProps {
  sx?: SxProps;
}

const FileTextIcon = ({ sx }: FileTextIconProps) => <DefaultIcon src={"fileText.svg"} sx={{ width: "16px", height: "16px", ...sx }} />;

export default FileTextIcon;
