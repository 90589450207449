import { SxProps } from "@mui/material";
import DefaultChip from "./DefaultChip";
import { User } from "../model/user.model";

interface GuardianChipProps {
  user: User;
  sx?: SxProps;
}

const GuardianChip = ({ user, sx }: GuardianChipProps) => {
  return (
    <DefaultChip
      text={`${user.firstName} ${user.lastName}`}
      sx={{
        backgroundColor: "rgba(230, 251, 241, 1)",
        height: "20px",
        ...sx,
      }}
    />
  );
};

export default GuardianChip;
