import { Box, CircularProgress, Input, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";

interface DefaultTextAreaProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  maxLength?: number;
  isLoading?: boolean;
  disabled?: boolean;
  wrapperSx?: SxProps;
}

const DefaultTextArea = ({ value, onChange, placeholder, wrapperSx, maxLength, isLoading, disabled }: DefaultTextAreaProps) => {
  return (
    <Box sx={{ position: "relative", ...wrapperSx }}>
      <Input
        disableUnderline
        multiline
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        minRows={10}
        maxRows={10}
        sx={{
          backgroundColor: "#FFF",
          border: "1px solid #B3BBC4",
          borderRadius: "8px",
          padding: "16px",
          "&:hover": { border: "1px solid #66389E" },
          "&:focus": { border: "1px solid #47EAD6" },
          "textarea::placeholder": { color: "#929CA6", fontSize: "14px", opacity: 1 },
          width: "inherit",
        }}
        inputProps={{ maxLength }}
      />
      <Box sx={{ position: "absolute", right: 16, bottom: 16, display: "flex" }}>
        {isLoading && <CircularProgress style={{ color: "#9154C9" }} size="15px" sx={{ mr: "10px" }} />}
        <DefaultText sx={{ color: "#929CA6" }}>{`${value.length}/${maxLength}`}</DefaultText>
      </Box>
    </Box>
  );
};

export default DefaultTextArea;
