import DefaultIcon from "./DefaultIcon";
import { SxProps } from "@mui/material";

interface IProps {
  sx?: SxProps;
}

const ChevronRightGreyIcon = ({ sx }: IProps) => <DefaultIcon src={"chevronRightGrey.svg"} sx={sx} />;

export default ChevronRightGreyIcon;
