import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import DefaultSecondaryButton from "./DefaultSecondaryButton";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DefaultDatePicker from "./DefaultDatePicker";
import ChipList from "./ChipList";
import EventChip from "./EventChip";
import { CreateEventsDto, DailyEvents } from "../model/event.model";
import { useAppState } from "../state/AppState";
import { TranslatedDictionaryEventType } from "../model/dictionary.model";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import DefaultModal from "./DefaultModal";
import LeaveConfirmation from "./LeaveConfirmation";
import { useClosePageModal } from "../util/closePageModal";
import { eventApi } from "../api/EventApi";

interface EventModalProps {
  handleClose: () => void;
  trackedEvent?: DailyEvents;
}

const EventModal = ({ handleClose, trackedEvent }: EventModalProps) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date>(new Date(trackedEvent?.date || dayjs().toISOString()));
  const [loading, setLoading] = useState<boolean>(false);
  const { studentEvents, dictionary, notification } = useAppState();
  const mappedEvents = trackedEvent?.events.map((event) => event.type);
  const existingEvents = studentEvents.list.find((item) => item.date === dayjs(date).format("YYYY-MM-DD"))?.events.map((event) => event.type) || [];
  const [dailyEvents, setDailyEvents] = useState<string[]>(mappedEvents || existingEvents);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [dateErrorText, setDateErrorText] = useState("");
  const { id } = useParams();

  useClosePageModal(date.toDateString());

  const handleSave = async () => {
    setLoading(true);
    const eventDto: CreateEventsDto = {
      date: dayjs(date).format("YYYY-MM-DD"),
      eventTypes: dailyEvents,
    };
    const dailyEvent: DailyEvents = {
      date: dayjs(date).format("YYYY-MM-DD"),
      events: dailyEvents
        ? dailyEvents.map((event) => {
            const dictionaryEvent = dictionary.getEventDto(event);
            return {
              type: dictionaryEvent?.key as string,
              category: dictionaryEvent?.eventCategory as string,
            };
          })
        : [],
    };
    const response = await eventApi.createOrUpdateEvent(id as string, eventDto);
    if (response.ok) {
      const thisEventExist = studentEvents.list.find((item) => item.date === dayjs(date).format("YYYY-MM-DD"));
      if (thisEventExist) {
        studentEvents.updateEvent(dailyEvent);
        notification.addNotification({
          title: t("event-edited"),
          message: t("event-edited-message"),
        });
      } else {
        studentEvents.addEvent(dailyEvent);
        notification.addNotification({
          title: t("event-created"),
          message: t("event-created-message"),
        });
      }
    }
    studentEvents.closeModalConfirmation = false;
    setLoading(false);
    handleClose();
  };

  const handleDateChange = (date: number) => {
    studentEvents.closeModalConfirmation = true;
    setDate(new Date(date));
    const existingEvents = studentEvents.list.find((item) => item.date === dayjs(date).format("YYYY-MM-DD"));
    if (existingEvents) {
      setDailyEvents(existingEvents.events.map((event) => event.type));
    } else {
      setDailyEvents([]);
    }
  };

  const handleEventClick = (event: TranslatedDictionaryEventType) => {
    studentEvents.closeModalConfirmation = true;
    if (dailyEvents.includes(event.key)) {
      setDailyEvents(dailyEvents.filter((dailyEvent) => dailyEvent !== event.key));
    } else {
      const sortedEvents = [...dailyEvents, event.key].sort((a, b) => a.localeCompare(b));
      setDailyEvents(sortedEvents);
    }
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleDateError = (error: string) => {
    setDateError(!!error);
    if (error === "minDate" || error === "maxDate") {
      setDateErrorText(t("only-90-days-back"));
    } else {
      setDateErrorText("");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <DefaultDatePicker
        label={t("event-date")}
        defaultValue={date}
        onChange={(date) => handleDateChange(date)}
        onError={(error) => handleDateError(error)}
        errorMsg={dateErrorText}
        minDate={dayjs().subtract(89, "day").toDate() as Date}
        maxDate={new Date()}
        disabled={!!trackedEvent}
      />
      <Box>
        <DefaultText sx={{ paddingBottom: "16px" }}>{t("good-things-happened-this-day")}</DefaultText>
        <ChipList
          sx={{ gap: "8px" }}
          items={dictionary.getGoodEvents().map((goodEvent, index) => (
            <Box onClick={() => handleEventClick(goodEvent)} key={index}>
              <EventChip
                event={goodEvent}
                selected={dailyEvents.some((item) => {
                  return item === goodEvent.key;
                })}
                sx={{ cursor: "pointer" }}
              />
            </Box>
          ))}
        />
      </Box>
      <Box>
        <DefaultText sx={{ paddingBottom: "16px" }}>{t("bad-things-happened-this-day")}</DefaultText>
        <ChipList
          sx={{ gap: "8px" }}
          items={dictionary.getBadEvents().map((badEvent, index) => (
            <Box onClick={() => handleEventClick(badEvent)} key={index}>
              <EventChip
                event={badEvent}
                selected={dailyEvents.some((item) => {
                  return item === badEvent.key;
                })}
                sx={{ cursor: "pointer" }}
              />
            </Box>
          ))}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "16px" }}>
        <DefaultSecondaryButton onClick={handleClose} sx={{ width: "160px", height: "44px", marginRight: "20px" }}>
          {t("cancel")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleSave} sx={{ width: "190px", height: "44px" }} isLoading={loading} disabled={!date || dailyEvents.length < 1 || loading || dateError}>
          {t("save")}
        </DefaultPrimaryButton>
      </Box>
      <DefaultModal modalTitle={t("leaving-the-page")} isOpen={isConfirmationOpen} onClose={handleCloseConfirmation}>
        <LeaveConfirmation handleClose={handleCloseConfirmation} handleLeave={handleClose} />
      </DefaultModal>
    </Box>
  );
};

export default EventModal;
