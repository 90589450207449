import { Box } from "@mui/material";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { ErrorBoundary } from "../ErrorBoundary";
import ErrorPage from "../pages/ErrorPage";
import { getErrorState } from "../state/ErrorState";
import Footer from "../Footer";
import Logo from "../components/Logo";
import { observer } from "mobx-react-lite";

const AnonymousLayout = () => {
  const errorState = getErrorState();

  return (
    <>
      {errorState.code !== null ? (
        <Box
          sx={{
            height: "100vh",
            paddingX: "5%",
            backgroundColor: "#F0F4FA",
          }}
        >
          <Logo sx={{ width: "201px", height: "42px", paddingTop: "60px", position: "absolute" }} />
          <ErrorPage code={errorState.code as number} />
          <Footer privacyPolicy sx={{ paddingTop: "40px" }} />
        </Box>
      ) : (
        <ErrorBoundary>
          <Box sx={{ height: "100vh", paddingX: "5%", overflowX: "hidden" }}>
            <Outlet />
            <Footer sx={{ paddingTop: "148px" }} />
          </Box>
        </ErrorBoundary>
      )}
      <ScrollRestoration />
    </>
  );
};

export default observer(AnonymousLayout);
