import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import EventsListImage from "./EventsListImage";
import TextHeader from "./TextHeader";

interface EmptyListProps {
  header: string;
  message: string;
}

const EmptyList = ({ header, message }: EmptyListProps) => {
  return (
    <Box
      sx={{
        marginTop: "35px",
        padding: "80px 32px",
        backgroundColor: "#F7F8FB",
        border: "1px solid rgba(213, 219, 227, 1)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(223,251,246, 1)",
          borderRadius: "50%",
          width: "160px",
          height: "160px",
        }}
      >
        <EventsListImage />
      </Box>
      <TextHeader sx={{ fontSize: "24px", paddingTop: "32px" }}>{header}</TextHeader>
      <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", paddingTop: "8px" }}>{message}</DefaultText>
    </Box>
  );
};

export default EmptyList;
