import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import DefaultModal from "./DefaultModal";
import DefaultText from "./DefaultText";
import { useAppState } from "../state/AppState";
import { useTranslation } from "react-i18next";
import { getErrorState } from "../state/ErrorState";
import RefreshIcon from "./icons/RefreshIcon";
import EnvelopeIcon from "./icons/EnvelopeIcon";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import DeclineIcon from "./icons/DeclineIcon";

interface RatingScaleErrorModalProps {
  studentId: string;
}

const RatingScaleErrorModal = ({ studentId }: RatingScaleErrorModalProps) => {
  const { t } = useTranslation();
  const errorState = getErrorState();
  const { ratingScaleFormState } = useAppState();
  const navigate = useNavigate();
  const { apiError } = ratingScaleFormState;

  useEffect(() => {
    return () => {
      ratingScaleFormState.apiError = null;
    };
  }, [ratingScaleFormState]);

  if (!apiError) {
    return <></>;
  }
  const errorKey = apiError?.errors.length && apiError.errors[0].key;
  if (!errorKey) {
    errorState.code = apiError.code;
    return <></>;
  }

  const title = t(`rating-scale-error-title.${errorKey}`, { defaultValue: "" });
  const message = t(`rating-scale-error-message.${errorKey}`, { defaultValue: "" });
  const buttonLabel = t(`rating-scale-error-button.${errorKey}`, { defaultValue: "" });

  if (!title || !message || !buttonLabel) {
    errorState.code = apiError.code;
    return <></>;
  }

  const getIcon = () => {
    switch (errorKey) {
      case "form.answer.not.found":
      case "form.concurrent.answer":
        return <RefreshIcon />;
      case "form.status.incorrect.REVOKED":
      case "form.status.revoked":
        return <DeclineIcon />;
      case "form.status.incorrect.COMPLETED":
        return <EnvelopeIcon />;
    }
  };

  const handleClose = () => {
    switch (errorKey) {
      case "form.answer.not.found":
      case "form.concurrent.answer":
        window.location.reload();
        break;
      case "form.status.incorrect.REVOKED":
      case "form.status.incorrect.COMPLETED":
      case "form.status.revoked":
        navigate(`/students/${studentId}/ratingScales`);
        break;
      default:
        errorState.code = apiError.code;
        break;
    }
  };

  return (
    <DefaultModal modalTitle={title} isOpen={true} titleIcon={getIcon()}>
      <DefaultText sx={{ fontSize: "16px" }}>{message}</DefaultText>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "16px", paddingTop: "40px" }}>
        <DefaultPrimaryButton onClick={handleClose} sx={{ height: "44px", minWidth: "100px" }}>
          {buttonLabel}
        </DefaultPrimaryButton>
      </Box>
    </DefaultModal>
  );
};

export default observer(RatingScaleErrorModal);
