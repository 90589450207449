import { Box } from "@mui/material";
import TextHeader from "../components/TextHeader";
import TextLabel from "../components/TextLabel";
import RegistrationStepper from "../components/RegistrationStepper";
import { Trans, useTranslation } from "react-i18next";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import DefaultSecondaryButton from "../components/DefaultSecondaryButton";
import { useAppState } from "../state/AppState";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import DefaultInput from "../components/DefaultInput";
import { useState } from "react";
import DefaultSelect, { SelectItem } from "../components/DefaultSelect";
import { validate, latinCharactersPattern } from "../util/validation";
import { invitationApi } from "../api/InvitationApi";
import { RegistrationDto } from "../model/user.model";
import { userApi } from "../api/UserApi";

const CreatePersonalProfile = () => {
  const { t } = useTranslation();
  const { user, notification } = useAppState();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [teacherProfession, setTeacherProfession] = useState("");
  const [teacherProfessionError, setTeacherProfessionError] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationNameError, setOrganizationNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const disabledContinueBtn =
    firstNameError.length > 0 ||
    lastNameError.length > 0 ||
    teacherProfessionError.length > 0 ||
    organizationNameError.length > 0 ||
    !firstName ||
    !lastName ||
    !teacherProfession ||
    !organizationName ||
    loading;

  const teacherProfessions: SelectItem[] = [
    {
      label: t("teacher"),
      value: "TEACHER",
    },
    {
      label: t("educational-resource-assistant"),
      value: "EDUCATIONAL_RESOURCE_ASSISTANT",
    },
    {
      label: t("special-education-teacher"),
      value: "SPECIAL_EDUCATION_TEACHER",
    },
    {
      label: t("school-nurse"),
      value: "SCHOOL_NURSE",
    },
    {
      label: t("school-psychologist"),
      value: "SCHOOL_PSYCHOLOGIST",
    },
    {
      label: t("guidance-counselor"),
      value: "GUIDANCE_COUNSELOR",
    },
  ];

  const handleBack = () => {
    navigate(`/register/step2${user.invitationUrl()}`);
  };

  const handleContinue = async () => {
    setLoading(true);
    const registrationData: RegistrationDto = {
      invitationId: user.invitationId as string,
      registrationToken: user.registrationToken as string,
      firstName,
      lastName,
      password: user.password as string,
      profession: teacherProfession,
      organizationName,
      acceptedTermsAndConditions: user.acceptedTermsAndConditions,
    };
    const registerResponse = await invitationApi.registerTeacher(registrationData);
    if (registerResponse.ok) {
      await login();
    }
    user.clearInvitationData();
  };

  const login = async (): Promise<void> => {
    const loginResult = await userApi.login(user.email as string, user.password as string, { showErrorPage: true });
    if (loginResult.ok) {
      navigate("/students");
      notification.addNotification({ title: t("registration-completed"), message: t("you-have-registered") });
      notification.addNotification({
        title: t("new-student-record"),
        message: (
          <Trans
            i18nKey="access-to-student"
            components={{ b: <b></b> }}
            values={{
              newStudentFirstName: user.newStudentFirstName,
              newStudentLastName: user.newStudentLastName,
            }}
          />
        ) as unknown as string,
      });
    }
  };

  const handleInputChange = (value: string, setter: (value: string) => void, errorSetter: (value: string) => void) => {
    setter(value);
    const error = validate(value, latinCharactersPattern);
    if (error) {
      errorSetter(t(error));
    } else {
      errorSetter("");
    }
  };

  return (
    <Box sx={{ paddingTop: "47px" }}>
      <TextLabel sx={{ paddingBottom: "2px", color: "rgba(78, 93, 105, 1)" }}>{t("register")}</TextLabel>
      <TextHeader sx={{ paddingBottom: "24px" }}>{t("credentials-for-account")}</TextHeader>
      <RegistrationStepper step={3} />
      <DefaultInput
        label={t("first-name")}
        error={firstNameError.length > 0}
        errorMsg={firstNameError}
        value={firstName}
        onChange={(value) => handleInputChange(value, setFirstName, setFirstNameError)}
        sx={{ width: "100%" }}
      />
      <DefaultInput
        label={t("last-name")}
        error={lastNameError.length > 0}
        errorMsg={lastNameError}
        value={lastName}
        onChange={(value) => handleInputChange(value, setLastName, setLastNameError)}
        sx={{ width: "100%", marginY: "34px" }}
      />
      <DefaultSelect
        label={t("profession")}
        error={teacherProfessionError.length > 0}
        errorMsg={teacherProfessionError}
        value={teacherProfession}
        data={teacherProfessions}
        selectHandler={(value) => handleInputChange(value, setTeacherProfession, setTeacherProfessionError)}
        sx={{ width: "100%", marginBottom: "58px" }}
      />
      <DefaultInput
        label={t("name-of-school")}
        hint={t("use-name-of-school")}
        error={organizationNameError.length > 0}
        errorMsg={organizationNameError}
        value={organizationName}
        onChange={(value) => handleInputChange(value, setOrganizationName, setOrganizationNameError)}
        sx={{ width: "100%" }}
      />
      <Box sx={{ paddingTop: "90px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DefaultSecondaryButton onClick={handleBack} sx={{ border: "none", padding: "0", height: "inherit", "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" } }}>
          {t("back")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleContinue} isLoading={loading} loadingText={t("loading")} disabled={disabledContinueBtn} sx={{ width: "180px" }}>
          {t("continue")}
        </DefaultPrimaryButton>
      </Box>
    </Box>
  );
};

export default observer(CreatePersonalProfile);
