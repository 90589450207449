import { useTranslation } from "react-i18next";
import TextHeader from "./TextHeader";
import { Box } from "@mui/material";
import { RatingScalesFormSummary } from "../model/ratingScales.model";
import RatingScalesFormInfoCard from "./RatingScalesFormInfoCard";
import RatingScalesFormProgressCard from "./RatingScalesFormProgressCard";

interface RatingScaleFormInformationProps {
  ratingScalesRequestForm: RatingScalesFormSummary;
}

const RatingScaleFormInformation = ({ ratingScalesRequestForm }: RatingScaleFormInformationProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <TextHeader>{t("form-information")}</TextHeader>
      <Box
        sx={{
          backgroundColor: "#F7F8FB",
          border: "1px solid #D5DBE3",
          borderRadius: "8px",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "18px",
          paddingBottom: "18px",
          marginTop: "16px",
          display: "flex",
          gap: "20px",
        }}
      >
        <RatingScalesFormInfoCard {...ratingScalesRequestForm} sx={{ flex: 1 }} />
        <RatingScalesFormProgressCard sx={{ flex: 1 }} />
      </Box>
    </Box>
  );
};

export default RatingScaleFormInformation;
