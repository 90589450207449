import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface CheckCircleIconProps {
  sx?: SxProps;
}

const CheckCirclePurpleIcon = ({ sx }: CheckCircleIconProps) => <DefaultIcon src={"checkCirclePurple.svg"} sx={{ width: "24px", height: "24px", ...sx }} />;

export default CheckCirclePurpleIcon;
