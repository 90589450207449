import { SxProps } from "@mui/material";
import DefaultPrimaryButton from "./DefaultPrimaryButton";

interface DefaultSecondaryButtonProps {
  children: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick?: () => void;
  sx?: SxProps;
}

const DefaultSecondaryButton = ({ children, startIcon, endIcon, onClick, sx }: DefaultSecondaryButtonProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <DefaultPrimaryButton
      onClick={handleClick}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        border: "1px solid rgba(180, 116, 237, 1)",
        color: "rgba(145, 84, 201, 1)",
        "&:hover": {
          color: "rgba(102, 56, 158, 1)",
          backgroundColor: "rgba(232, 229, 255, 1)",
        },
        backgroundColor: "rgba(255, 255, 255, 1)",
        ...sx,
      }}
    >
      {children}
    </DefaultPrimaryButton>
  );
};

export default DefaultSecondaryButton;
