import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAppState } from "../state/AppState";
import { Box, Skeleton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { userApi } from "../api/UserApi";
import { withLoader } from "../hoc/withLoader";
import EventsHistory from "./EventsHistory";
import { eventApi } from "../api/EventApi";
import { StudentProfileTab } from "../state/StudentProfilePageState";

const LoadingEventsHistory = withLoader(EventsHistory);

const EventsHistoryLoader = () => {
  const { t } = useTranslation();
  const { dictionary, studentEvents, studentProfilePageState } = useAppState();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
      const dictionaryResponse = await userApi.getDictionary(userLocale);
      if (dictionaryResponse.ok && dictionaryResponse.data) {
        dictionary.eventTypes = dictionaryResponse.data.eventTypes;
      }
      const from = dayjs().subtract(Number(89), "days").format("YYYY-MM-DD");
      const to = dayjs().format("YYYY-MM-DD");
      const result = await eventApi.getStudentEvents(id as string, from, to);
      if (result.ok && result.data) {
        studentEvents.list = result.data;
      }
      setLoading(false);
    };
    getData();
  }, [id, studentEvents, dictionary]);

  useEffect(() => {
    studentProfilePageState.activeTab = StudentProfileTab.EVENTS;
  }, [studentProfilePageState]);

  return (
    <>
      <TextHeader sx={{ marginBottom: "61px" }}>{t("events-history")}</TextHeader>
      <LoadingEventsHistory isLoading={loading}>
        {Array(1)
          .fill(1)
          .map((el, i) => (
            <Box key={i} sx={{ height: 194, paddingBottom: "24px" }}>
              <Skeleton variant="rounded" sx={{ height: "inherit" }} />
            </Box>
          ))}
      </LoadingEventsHistory>
    </>
  );
};

export default observer(EventsHistoryLoader);
