import { useNavigate } from "react-router-dom";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import DefaultText from "./DefaultText";
import TextHeader from "./TextHeader";
import { useTranslation } from "react-i18next";

const PasswordChanged = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <TextHeader>{t("password-changed")}</TextHeader>
      <DefaultText sx={{ fontSize: "17px", lineHeight: "24px", color: "rgba(45, 66, 81, 1)", paddingTop: "10px" }}>{t("please-login-with-new-password")}</DefaultText>
      <DefaultPrimaryButton onClick={() => navigate("/login")} sx={{ width: "323px", marginTop: "42px" }}>
        {t("login")}
      </DefaultPrimaryButton>
    </>
  );
};

export default PasswordChanged;
