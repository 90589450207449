import { Box, Button, ButtonGroup, CircularProgress, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import { SelectItem } from "./DefaultSelect";

interface DefaultButtonGroupProps {
  buttons: SelectItem[];
  selectedValue?: string;
  disabled?: boolean;
  loadingValue?: string;
  sx?: SxProps;
  onClick: (value: string) => void;
}

const DefaultButtonGroup = ({ buttons, selectedValue, onClick, disabled, loadingValue, sx }: DefaultButtonGroupProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {loadingValue && <CircularProgress style={{ color: "#9154C9" }} size="20px" sx={{ mr: "10px" }} />}
      <ButtonGroup
        variant="outlined"
        disabled={disabled}
        sx={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)", borderRadius: "8px", ...sx }}
      >
        {buttons.map((button) => {
          const isLoading = loadingValue === button.value;
          const isSelected = button.value === selectedValue;
          let textColor = "rgba(4, 11, 16, 1)";
          if (isLoading) {
            textColor = "#4E5D69";
          } else if (loadingValue) {
            textColor = isSelected ? "#4E5D69" : "#929CA6";
          }
          return (
            <Button
              disableRipple
              key={button.value}
              onClick={() => onClick(button.value as string)}
              sx={{
                textWrap: "nowrap",
                maxWidth: "200px",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "16px",
                paddingRight: "16px",
                textTransform: "none",
                borderColor: "#DEE3E9",
                borderRadius: "8px",
                backgroundColor: isLoading ? "#DEE3E9" : isSelected ? "#D5A8FF" : undefined,
                "&:hover": { backgroundColor: isSelected ? "#D5A8FF" : "#E8E5FF", borderColor: "#DEE3E9" },
              }}
            >
              <DefaultText sx={{ textOverflow: "ellipsis", overflow: "hidden", color: textColor }}>{button.label}</DefaultText>
            </Button>
          );
        })}
      </ButtonGroup>
    </Box>
  );
};

export default DefaultButtonGroup;
