import locale_en from "./translations/en.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

enum Language {
  ENGLISH = "en",
}

const translations: Record<Language, { translation: object }> = {
  [Language.ENGLISH]: {
    translation: locale_en,
  },
};

export const initTranslations = () => {
  i18n.use(initReactI18next).init({
    resources: translations,
    lng: Language.ENGLISH,
    fallbackLng: Language.ENGLISH,
    interpolation: {
      escapeValue: false,
    },
  });
};
