import { makeAutoObservable } from "mobx";

export class UserState {
  private _email: string | null = null;
  private _password: string | null = null;
  private _invitationId: string | null = null;
  private _invitationToken: string | null = null;
  private _registrationToken: string | null = null;
  private _acceptedTermsAndConditions = false;
  private _newStudentFirstName: string | null = null;
  private _newStudentLastName: string | null = null;

  constructor() {
    makeAutoObservable(this);
    this.loadUserFromSessionStorage();
  }

  set isAuthorized(authorized: boolean) {
    if (authorized) {
      localStorage.setItem("user.authorized", "true");
    } else {
      localStorage.removeItem("user.authorized");
    }
  }

  get isAuthorized(): boolean {
    return localStorage.getItem("user.authorized") === "true";
  }

  set email(email: string | null) {
    this._email = email;
    if (email) {
      sessionStorage.setItem("user.email", email);
    } else {
      sessionStorage.removeItem("user.email");
    }
  }

  get email(): string | null {
    return this._email;
  }

  set password(password: string | null) {
    this._password = password;
    if (password) {
      sessionStorage.setItem("user.password", password);
    } else {
      sessionStorage.removeItem("user.password");
    }
  }

  get password(): string | null {
    return this._password;
  }

  set invitationId(invitationId: string | null) {
    this._invitationId = invitationId;
    if (invitationId) {
      sessionStorage.setItem("user.invitationId", invitationId);
    } else {
      sessionStorage.removeItem("user.invitationId");
    }
  }

  get invitationId(): string | null {
    return this._invitationId;
  }

  set invitationToken(token: string | null) {
    this._invitationToken = token;
    if (token) {
      sessionStorage.setItem("user.invitationToken", token);
    } else {
      sessionStorage.removeItem("user.invitationToken");
    }
  }

  get invitationToken(): string | null {
    return this._invitationToken;
  }

  set registrationToken(token: string | null) {
    this._registrationToken = token;
    if (token) {
      sessionStorage.setItem("user.registrationToken", token);
    } else {
      sessionStorage.removeItem("user.registrationToken");
    }
  }

  get registrationToken(): string | null {
    return this._registrationToken;
  }

  set newStudentFirstName(newStudentFirstName: string | null) {
    this._newStudentFirstName = newStudentFirstName;
    if (newStudentFirstName) {
      sessionStorage.setItem("user.newStudentFirstName", newStudentFirstName);
    } else {
      sessionStorage.removeItem("user.newStudentFirstName");
    }
  }

  get newStudentFirstName(): string | null {
    return this._newStudentFirstName;
  }

  set newStudentLastName(newStudentLastName: string | null) {
    this._newStudentLastName = newStudentLastName;
    if (newStudentLastName) {
      sessionStorage.setItem("user.newStudentLastName", newStudentLastName);
    } else {
      sessionStorage.removeItem("user.newStudentLastName");
    }
  }

  get newStudentLastName(): string | null {
    return this._newStudentLastName;
  }

  set acceptedTermsAndConditions(accepted: boolean) {
    this._acceptedTermsAndConditions = accepted;
    if (accepted) {
      sessionStorage.setItem("user.acceptedTermsAndConditions", "true");
    } else {
      sessionStorage.removeItem("user.acceptedTermsAndConditions");
    }
  }

  get acceptedTermsAndConditions(): boolean {
    return this._acceptedTermsAndConditions;
  }

  loadUserFromSessionStorage() {
    const email = sessionStorage.getItem("user.email");
    const password = sessionStorage.getItem("user.password");
    const invitationId = sessionStorage.getItem("user.invitationId");
    const invitationToken = sessionStorage.getItem("user.invitationToken");
    const registrationToken = sessionStorage.getItem("user.registrationToken");
    const acceptedTermsAndConditions = sessionStorage.getItem("user.acceptedTermsAndConditions");
    const newStudentFirstName = sessionStorage.getItem("user.newStudentFirstName");
    const newStudentLastName = sessionStorage.getItem("user.newStudentLastName");
    if (email) {
      this._email = email;
    }
    if (password) {
      this._password = password;
    }
    if (invitationToken) {
      this._invitationToken = invitationToken;
    }
    if (invitationId) {
      this._invitationId = invitationId;
    }
    if (registrationToken) {
      this._registrationToken = registrationToken;
    }
    if (acceptedTermsAndConditions) {
      this._acceptedTermsAndConditions = acceptedTermsAndConditions === "true";
    }
    if (newStudentFirstName) {
      this._newStudentFirstName = newStudentFirstName;
    }
    if (newStudentLastName) {
      this._newStudentLastName = newStudentLastName;
    }
  }

  clearInvitationData() {
    this._email = null;
    this._password = null;
    this._invitationId = null;
    this._invitationToken = null;
    this._registrationToken = null;
    this._acceptedTermsAndConditions = false;
    this._newStudentFirstName = null;
    this._newStudentLastName = null;
    sessionStorage.removeItem("user.email");
    sessionStorage.removeItem("user.password");
    sessionStorage.removeItem("user.invitationId");
    sessionStorage.removeItem("user.invitationToken");
    sessionStorage.removeItem("user.registrationToken");
    sessionStorage.removeItem("user.acceptedTermsAndConditions");
    sessionStorage.removeItem("user.newStudentFirstName");
    sessionStorage.removeItem("user.newStudentLastName");
  }

  isInvited(): boolean {
    return this._invitationToken !== null && this._registrationToken !== null && this._invitationId !== null;
  }

  invitationUrl(): string {
    return `?id=${this._invitationId}&token=${this._invitationToken}`;
  }
}
