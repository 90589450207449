import { Select, SelectChangeEvent, SxProps, MenuItem, Box, FormControl, CircularProgress } from "@mui/material";
import DefaultText from "./DefaultText";
import CaretDownIcon from "./icons/CaretDownIcon";
import WarningIcon from "./icons/Warning";

interface DefaultSelectProps {
  label?: string;
  value?: string;
  data: SelectItem[];
  sx?: SxProps;
  selectHandler?: (value: string) => void;
  error?: boolean;
  errorMsg?: string;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  menuItemSx?: SxProps;
}

export interface SelectItem {
  label: string;
  value: string | number;
}

const DefaultSelect = ({ label, value, selectHandler, error, errorMsg, placeholder, data, disabled, isLoading, sx, menuItemSx }: DefaultSelectProps) => {
  const handleSelectChange = (event: SelectChangeEvent) => {
    if (selectHandler) {
      selectHandler(event.target.value);
    }
  };

  const onBlur = () => {
    if (selectHandler && !value) {
      selectHandler("");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isLoading && <CircularProgress style={{ color: "#9154C9" }} size="20px" sx={{ mr: "10px" }} />}
      <FormControl sx={{ width: "320px", height: "48px", ...sx }}>
        {label && <DefaultText sx={{ marginBottom: "8px" }}>{label}</DefaultText>}
        <Select
          value={value}
          disabled={disabled}
          onChange={handleSelectChange}
          onBlur={onBlur}
          IconComponent={CaretDownIcon}
          error={error}
          sx={{
            height: "inherit",
            lineHeight: "16px",
            color: "rgba(146, 156, 166, 1)",
            border: "1px solid #B3BBC4",
            paddingTop: "8px",
            borderRadius: "8px",
            backgroundColor: "rgba(255, 255, 255, 1)",
            "& .MuiInput-input:focus": { backgroundColor: "rgba(255, 255, 255, 1)" },
            "& .MuiSelect-icon": { marginRight: "8px", marginTop: "-4px" },
            width: "inherit",
            "& .MuiSelect-select .notranslate::after": placeholder
              ? {
                  content: `"${placeholder}"`,
                  color: "rgba(146, 156, 166, 1)",
                  fontSize: "14px",
                }
              : {},
            "&:hover": { border: "1px solid #66389E" },
            "&:focus": { border: "1px solid #47EAD6" },
            ".MuiOutlinedInput-notchedOutline": { border: "unset" },
            "&.Mui-disabled": { backgroundColor: "#DEE3E9" },
            ".MuiTypography-root": { overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" },
          }}
        >
          {data.map((item) => {
            return (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{
                  paddingLeft: "32px",
                  paddingRight: "32px",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  "&:focus": { backgroundColor: "rgba(255, 255, 255, 1)" },
                  "&:hover": { backgroundColor: "#E8E5FF" },
                  "&.Mui-selected": {
                    backgroundColor: "#D5A8FF",
                    "&:hover": { backgroundColor: "#D5A8FF" },
                  },
                  ...menuItemSx,
                }}
              >
                <DefaultText sx={{ textWrap: "wrap", color: disabled ? "#4E5D69" : "rgba(4, 11, 16, 1)" }}>{item.label}</DefaultText>
              </MenuItem>
            );
          })}
        </Select>
        {errorMsg && (
          <Box sx={{ paddingTop: "8px", display: "flex" }}>
            <WarningIcon sx={{ paddingRight: "7px" }} />
            <DefaultText sx={{ fontSize: "10px", color: "rgba(221, 49, 49, 1)" }}>{errorMsg}</DefaultText>
          </Box>
        )}
      </FormControl>
    </Box>
  );
};

export default DefaultSelect;
