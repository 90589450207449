import { Box } from "@mui/material";
import { t } from "i18next";
import DefaultModal from "./DefaultModal";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import DefaultText from "./DefaultText";
import CheckCircleIcon from "./icons/CheckCircleIcon";

interface RatingScaleSubmitFormSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const RatingScaleSubmitFormSuccessModal = ({ isOpen, onClose }: RatingScaleSubmitFormSuccessModalProps) => {
  return (
    <DefaultModal modalTitle={t("submit-form-success-title")} isOpen={isOpen} titleIcon={<CheckCircleIcon />}>
      <DefaultText sx={{ fontSize: "16px" }}>{t("submit-form-success-message")}</DefaultText>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "16px", paddingTop: "40px" }}>
        <DefaultPrimaryButton onClick={onClose} sx={{ height: "44px", minWidth: "100px" }}>
          {t("close")}
        </DefaultPrimaryButton>
      </Box>
    </DefaultModal>
  );
};

export default RatingScaleSubmitFormSuccessModal;
