import { Box } from "@mui/material";
import Logo from "../components/Logo";
import DefaultText from "../components/DefaultText";
import { useTranslation } from "react-i18next";
import TextHeader from "../components/TextHeader";
import HeaderImage from "../components/HeaderImage";

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Logo sx={{ paddingY: "28px" }} />
      <HeaderImage sx={{ marginX: "-5.7%" }} />
      <Box sx={{ color: "rgba(4, 11, 16, 1)", width: "65%" }}>
        <TextHeader sx={{ paddingTop: "70px", fontSize: "48px", lineHeight: "56px" }}>{t("privacy-policy")}</TextHeader>
        <DefaultText sx={{ paddingTop: "16px", fontSize: "18px", lineHeight: "24px" }}>Updated August 22, 2023</DefaultText>
        <TextHeader sx={{ paddingTop: "47px", fontSize: "32px", lineHeight: "48px" }}>What Is Personal Data at Oysta?</TextHeader>
        <DefaultText sx={{ paddingTop: "32px", fontSize: "18px", lineHeight: "24px" }}>
          At Oysta, we believe strongly in fundamental privacy rights — and that those fundamental rights should not differ depending on where you live in the world. That’s why we
          treat any data that relates to an identified or identifiable individual or that is linked or linkable to them by Oysta as “personal data,” no matter where the individual
          lives. This means that data that directly identifies you — such as your name — is personal data, and also data that does not directly identify you, but that can
          reasonably be used to identify you — such as the serial number of your device — is personal data. Aggregated data is considered non‑personal data for the purposes of this
          Privacy Policy. This Privacy Policy covers how Oysta or an Oysta-affiliated company (collectively, “Oysta”) handles personal data whether you interact with us on our
          websites, through Oysta apps (such as Oysta Music or Wallet), or in person (including by phone or when visiting our retail stores). Oysta may also link to third parties
          on our services or make third-party apps available for download in our App Store. Oysta’s Privacy Policy does not apply to how third parties define personal data or how
          they use it. We encourage you to read their privacy policies and know your privacy rights before interacting with them.
        </DefaultText>
        <TextHeader sx={{ paddingTop: "64px", fontSize: "32px", lineHeight: "48px" }}>Accounts, Passwords and Security</TextHeader>
        <DefaultText sx={{ paddingTop: "32px", fontSize: "18px", lineHeight: "24px" }}>
          Certain features or services offered on or through the Site may require you to open an account (including setting up an Oysta ID and password). You are entirely
          responsible for maintaining the confidentiality of the information you hold for your account, including your password, and for any and all activity that occurs under your
          account as a result of your failing to keep this information secure and confidential. You agree to notify Oysta immediately of any unauthorized use of your account or
          password, or any other breach of security. You may be held liable for losses incurred by Oysta or any other user of or visitor to the Site due to someone else using your
          Oysta ID, password or account as a result of your failing to keep your account information secure and confidential. You may not use anyone else’s Oysta ID, password or
          account at any time without the express permission and consent of the holder of that Oysta ID, password or account. Oysta cannot and will not be liable for any loss or
          damage arising from your failure to comply with these obligations.
        </DefaultText>
        <TextHeader sx={{ paddingTop: "64px", fontSize: "32px", lineHeight: "48px" }}>Your Privacy Rights at Oysta</TextHeader>
        <DefaultText sx={{ paddingTop: "32px", fontSize: "18px", lineHeight: "24px" }}>
          At Oysta, we respect your ability to know, access, correct, transfer, restrict the processing of, and delete your personal data. We have provided these rights to our
          global customer base and if you choose to exercise these privacy rights, you have the right not to be treated in a discriminatory way nor to receive a lesser degree of
          service from Oysta. Where you are requested to consent to the processing of your personal data by Oysta, you have the right to withdraw your consent at any time. To
          exercise your privacy rights and choices including where a third-party service provider is acting on Oysta’s behalf, visit the Oysta Data and Privacy page at
          privacy.Oysta.com for Oysta or shazam.com/privacy for Shazam. To help protect the security of your personal data, you must sign in to your account and your identity will
          be verified. If you want to obtain a copy of personal data that is not currently available from privacy.Oysta.com, you can make a request at
          Oysta.com/legal/privacy/contact. There may be situations where we cannot grant your request — for example, if you ask us to delete your transaction data and Oysta is
          legally obligated to keep a record of that transaction to comply with law. We may also decline to grant a request where doing so would undermine our legitimate use of
          data for anti-fraud and security purposes, such as when you request deletion of an account that is being investigated for security concerns. Other reasons your privacy
          request may be denied are if it jeopardizes the privacy of others, is frivolous or vexatious, or would be extremely impractical.
        </DefaultText>
        <TextHeader sx={{ paddingTop: "64px", fontSize: "32px", lineHeight: "48px" }}>Personal Data Oysta Collects from You</TextHeader>
        <DefaultText sx={{ paddingTop: "32px", fontSize: "18px", lineHeight: "24px" }}>
          At Oysta, we believe that you can have great products and great privacy. This means that we strive to collect only the personal data that we need. Descriptions of how
          Oysta handles personal data for certain individual services are available at Oysta.com/legal/privacy/data. When you create an Oysta ID, apply for commercial credit,
          purchase and/or activate a product or device, download a software update, register for a class at an Oysta Store, connect to our services, contact us (including by social
          media), participate in an online survey, or otherwise interact with Oysta, we may collect a variety of information, including:
        </DefaultText>
        <ul style={{ marginTop: "0px", marginBottom: "0px", fontSize: "18px", lineHeight: "24px", paddingLeft: "30px" }}>
          <li>Account Information. Your Oysta ID and related account details, including email address, devices registered, account status, and age</li>
          <li>Device Information. Data from which your device could be identified, such as device serial number, or about your device, such as browser type</li>
          <li>Contact Information. Data such as name, email address, physical address, phone number, or other contact information</li>
          <li>Payment Information. Data about your billing address and method of payment, such as bank details, credit, debit, or other payment card information </li>
          <li>Transaction Information. Data about purchases of Oysta products and services or transactions facilitated by Oysta, including purchases on Oysta platforms</li>
          <li>Fraud Prevention Information. Data used to help identify and prevent fraud, including a device trust score</li>
          <li>
            Usage Data. Data about your activity on and use of our offerings, such as app launches within our services, including browsing history; search history; product
            interaction; crash data, performance and other diagnostic data; and other usage data
          </li>
          <li>Location Information. Precise location only to support services such as Find My or where you agree for region-specific services, and coarse location</li>
          <li>
            Health Information. Data relating to the health status of an individual, including data related to one’s physical or mental health or condition. Personal health data
            also includes data that can be used to make inferences about or detect the health status of an individual. If you participate in a study using an Oysta Health Research
            Study app, the policy governing the privacy of your personal data is described in the Oysta Health Study Apps Privacy Policy.
          </li>
          <li>Fitness Information. Details relating to your fitness and exercise information where you choose to share them</li>
          <li>Financial Information. Details including salary, income, and assets information where collected, and information related to Oysta-branded financial offerings</li>
          <li>
            Government ID Data. In certain jurisdictions, we may ask for a government-issued ID in limited circumstances, including when setting up a wireless account and
            activating your device, for the purpose of extending commercial credit, managing reservations, or as required by law
          </li>
          <li>
            Other Information You Provide to Us. Details such as the content of your communications with Oysta, including interactions with customer support and contacts through
            social media channels
          </li>
        </ul>
        <DefaultText sx={{ fontSize: "18px", lineHeight: "24px" }}>
          You are not required to provide the personal data that we have requested. However, if you choose not to do so, in many cases we will not be able to provide you with our
          products or services or respond to requests you may have.
        </DefaultText>
        <TextHeader sx={{ paddingTop: "64px", fontSize: "32px", lineHeight: "48px" }}>Personal Data Oysta Receives from Other Sources</TextHeader>
        <DefaultText sx={{ paddingTop: "32px", fontSize: "18px", lineHeight: "24px" }}>
          Oysta may receive personal data about you from other individuals, from businesses or third parties acting at your direction, from our partners who work with us to provide
          our products and services and assist us in security and fraud prevention, and from other lawful sources.
        </DefaultText>
        <ul style={{ marginTop: "0px", marginBottom: "0px", fontSize: "18px", lineHeight: "24px", paddingLeft: "30px" }}>
          <li>
            Individuals. Oysta may collect data about you from other individuals — for example, if that individual has sent you a product or gift card, invited you to participate
            in an Oysta service or forum, or shared content with you.
          </li>
          <li>
            At Your Direction. You may direct other individuals or third parties to share data with Oysta. For example, you may direct your mobile carrier to share data about your
            carrier account with Oysta for account activation, or for your loyalty program to share information about your participation so that you can earn rewards for Oysta
            purchases.
          </li>
          <li>
            Oysta Partners. We may also validate the information you provide — for example, when creating an Oysta ID, with a third party for security, and for fraud-prevention
            purposes.
          </li>
        </ul>
        <DefaultText sx={{ fontSize: "18px", lineHeight: "24px" }}>
          For research and development purposes, we may use datasets such as those that contain images, voices, or other data that could be associated with an identifiable person.
          When acquiring such datasets, we do so in accordance with applicable law, including law in the jurisdiction in which the dataset is hosted. When using such datasets for
          research and development, we do not attempt to reidentify individuals who may appear therein.
        </DefaultText>
      </Box>
    </>
  );
};

export default PrivacyPolicyPage;
