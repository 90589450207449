import { Box, SxProps } from "@mui/material";
import bannerImg from "../assets/img/banner.svg";

interface HeaderImage {
  sx?: SxProps;
}

const HeaderImage = ({ sx }: HeaderImage) => <Box sx={{ backgroundImage: `url(${bannerImg})`, height: "160px", ...sx }} />;

export default HeaderImage;
