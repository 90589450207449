import { makeAutoObservable } from "mobx";
import { DailyEvents } from "../model/event.model";
import dayjs from "dayjs";

export class StudentEventsState {
  private _list: DailyEvents[] = [];
  private _closeModalConfirmation = false;

  constructor() {
    makeAutoObservable(this);
  }

  get list() {
    return this._list;
  }

  set list(events: DailyEvents[]) {
    this._list = events;
  }

  get closeModalConfirmation() {
    return this._closeModalConfirmation;
  }

  set closeModalConfirmation(closeModalConfirmation: boolean) {
    this._closeModalConfirmation = closeModalConfirmation;
  }

  addEvent = (event: DailyEvents) => {
    this.list.push(event);
    this.sortList();
  };

  deleteEvent = (event: DailyEvents) => {
    this.list = this.list.filter((n: DailyEvents) => n.date !== event.date);
  };

  sortList = () => {
    this.list.sort((a, b) => dayjs(b.date, "YYYY-MM-DD").valueOf() - dayjs(a.date, "YYYY-MM-DD").valueOf());
  };

  updateEvent = (event: DailyEvents) => {
    this.deleteEvent(event);
    this.addEvent(event);
  };
}
