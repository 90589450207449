import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppState } from "../state/AppState";
import { StudentProfileTab } from "../state/StudentProfilePageState";
import RatingScalesRequestsFormInfoContainer from "./RatingScalesRequestsFormInfoContainer";
import RatingScalesRequestsListContainer from "./RatingScalesRequestsListContainer";

const RatingScalesRequestsTabRouter = () => {
  const { studentProfilePageState } = useAppState();

  useEffect(() => {
    studentProfilePageState.activeTab = StudentProfileTab.RATING_SCALES;
  }, [studentProfilePageState]);

  return (
    <Routes>
      <Route path="" element={<RatingScalesRequestsListContainer />} />
      <Route path="/:ratingScaleId" element={<RatingScalesRequestsFormInfoContainer />} />
    </Routes>
  );
};

export default RatingScalesRequestsTabRouter;
