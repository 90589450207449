import { makeAutoObservable } from "mobx";
import { Component, TranslatedDictionaryEventType } from "../model/dictionary.model";
import { EventCategory } from "../model/event.model";

export class DictionaryState {
  private _eventTypes: TranslatedDictionaryEventType[] = [];

  constructor() {
    makeAutoObservable(this);
    this.loadUserFromLocalStorage();
  }

  set eventTypes(eventTypes: TranslatedDictionaryEventType[]) {
    this._eventTypes = eventTypes;
    if (eventTypes) {
      localStorage.setItem("dictionary.eventTypes", JSON.stringify(eventTypes));
    } else {
      localStorage.removeItem("dictionary.eventTypes");
    }
  }

  get eventTypes(): TranslatedDictionaryEventType[] {
    return this._eventTypes;
  }

  loadUserFromLocalStorage() {
    const eventTypes = localStorage.getItem("dictionary.eventTypes");
    if (eventTypes) {
      this._eventTypes = JSON.parse(eventTypes);
    }
  }

  getGoodEvents() {
    return this._eventTypes.filter((item) => item.eventCategory === EventCategory.GOOD_DAY && item.components.includes(Component.TEACHER_WEB_APP));
  }

  getBadEvents() {
    return this._eventTypes.filter((item) => item.eventCategory === EventCategory.BAD_DAY && item.components.includes(Component.TEACHER_WEB_APP));
  }

  getEventDto(eventType: string) {
    return this._eventTypes.find((item) => item.key === eventType);
  }
}
