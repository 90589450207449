import { Box, SxProps } from "@mui/material";

interface DefaultIconProps {
  src: string;
  sx?: SxProps;
}

const DefaultIcon = ({ src, sx }: DefaultIconProps) => {
  return <Box component="img" src={require(`../../assets/img/${src}`)} sx={{ width: "24px", height: "24px", ...sx }} />;
};

export default DefaultIcon;
