import Link from "./Link";
import PurpleArrowLeftIcon from "./icons/PurpleArrowLeftIcon";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const BackToRatingScalesLink = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <Link
      to={`/students/${id}/ratingScales`}
      sx={{ gap: "8px", display: "flex", width: "fit-content", alignItems: "center", fontSize: "16px", color: "rgba(145, 84, 201, 1)", marginBottom: "32px", marginTop: "12px" }}
    >
      <PurpleArrowLeftIcon />
      {t("back-to-rating-scales")}
    </Link>
  );
};

export default BackToRatingScalesLink;
