import DefaultChip from "./DefaultChip";

interface SelectedAnswerChipProps {
  answer: string;
}

const SelectedAnswerChip = ({ answer }: SelectedAnswerChipProps) => {
  return <DefaultChip text={answer} sx={{ padding: "4px 10px", backgroundColor: "rgba(240, 244, 250, 1)", fontSize: "16px" }} />;
};

export default SelectedAnswerChip;
