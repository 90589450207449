import { Api, ApiResponsePromise, withErrorHandling, withErrorPage } from "./Api";
import { InvitationData, RegistrationTokenDto } from "../model/invitationData.model";
import { RegistrationDto } from "../model/user.model";

class InvitationApi extends Api {
  getAccessData = (invitationId: string, invitationToken: string): ApiResponsePromise<InvitationData> => {
    const url = `invitations/${invitationId}/access`;
    return this.post(url, withErrorHandling({ requestPayload: { token: invitationToken } }));
  };
  validateInvitationCode = (invitationId: string, invitationToken: string, invitationCode: string): ApiResponsePromise<RegistrationTokenDto> => {
    const url = `invitations/${invitationId}/verification`;
    return this.post(url, withErrorHandling({ requestPayload: { token: invitationToken, invitationCode } }));
  };
  registerTeacher = (registrationDto: RegistrationDto): ApiResponsePromise<void> => {
    const { invitationId, registrationToken, firstName, lastName, password, profession, organizationName, acceptedTermsAndConditions } = registrationDto;
    const url = `invitations/${invitationId}/register/teacher`;
    const requestPayload = { registrationToken, firstName, lastName, password, profession, organizationName, acceptedTermsAndConditions };
    return this.post(url, withErrorPage({ requestPayload, expectJsonResponse: false }));
  };
}

export const invitationApi = new InvitationApi();
