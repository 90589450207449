import { SxProps } from "@mui/material";
import DefaultIcon from "./DefaultIcon";

interface WarningIconProps {
  sx?: SxProps;
}

const WarningPurpleIcon = ({ sx }: WarningIconProps) => <DefaultIcon src={"warningPurple.svg"} sx={{ width: "16px", height: "16px", ...sx }} />;

export default WarningPurpleIcon;
