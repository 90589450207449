export interface DailyEvents {
  date: string;
  events: EventDto[];
}

export enum EventCategory {
  GOOD_DAY = "GOOD_DAY",
  BAD_DAY = "BAD_DAY",
}

export interface EventDto {
  type: string;
  category: string;
}

export interface CreateEventsDto {
  date: string;
  eventTypes: string[];
}
