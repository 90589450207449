import { Student, StudentListEntry } from "../model/student.model";
import { Api, ApiResponsePromise, withErrorPage } from "./Api";
import { refreshAccessTokenHook } from "./responseHooks/refreshAccessTokenHook";

class StudentApi extends Api {
  getStudent = (id: string): ApiResponsePromise<Student> => this.get(`students/${id}`, withErrorPage());
  getStudents = (): ApiResponsePromise<StudentListEntry[]> => this.get("students", withErrorPage());
}

export const studentApi = new StudentApi().useResponseHooks([refreshAccessTokenHook]);
