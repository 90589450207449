import { Api, ApiResponsePromise, RequestParams, withErrorHandling, withErrorPage } from "./Api";
import { User } from "../model/user.model";
import { refreshAccessTokenHook } from "./responseHooks/refreshAccessTokenHook";
import { Dictionary } from "../model/dictionary.model";

class UserApi extends Api {
  login = (email: string, password: string, params?: Partial<RequestParams>): ApiResponsePromise<void> => {
    return this.post(
      "teachers/login",
      withErrorHandling({
        requestPayload: { email, password },
        refreshAT: false,
        expectJsonResponse: false,
        ...params,
      })
    );
  };
  me = (params?: Partial<RequestParams>): ApiResponsePromise<User> => {
    return this.get("teachers/me", withErrorHandling(params));
  };
  logout = (): ApiResponsePromise<void> => {
    return this.post("teachers/logout", withErrorHandling({ refreshAT: false, expectJsonResponse: false }));
  };
  resetPasswordInit = (email: string): ApiResponsePromise<void> => {
    return this.post("password/reset/init", withErrorHandling({ requestPayload: { email }, refreshAT: false, expectJsonResponse: false }));
  };
  resetPasswordVerify = (email: string, token: string): ApiResponsePromise<void> => {
    return this.post("password/reset/verify", withErrorHandling({ requestPayload: { email, token }, refreshAT: false, expectJsonResponse: false }));
  };
  resetPasswordConfirm = (email: string, token: string, password: string, confirmationCode: string): ApiResponsePromise<void> => {
    return this.post("password/reset/confirm", withErrorHandling({ requestPayload: { email, token, password, confirmationCode }, refreshAT: false, expectJsonResponse: false }));
  };
  getDictionary = (locale: string): ApiResponsePromise<Dictionary> => {
    return this.get(`dictionary/teacher?locale=${locale}`, withErrorPage());
  };
}

export const userApi = new UserApi().useResponseHooks([refreshAccessTokenHook]);
