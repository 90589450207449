import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { initTranslations } from "./i18n";
import { RootContext, state } from "./state/AppState";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import MainLayout from "./layouts/MainLayout";
import MyStudentsPage from "./pages/MyStudentsPage";
import StudentProfilePage from "./pages/StudentProfilePage";
import HelpCenterPage from "./pages/HelpCenterPage";
import RegistrationLayout from "./layouts/RegistrationLayout";
import AnonymousLayout from "./layouts/AnonymousLayout";
import NewInvitationPage from "./pages/NewInvitationPage";
import InvitationGuard from "./InvitationGuard";
import CreateCredentialsPage from "./pages/CreateCredentialsPage";
import DeclinedTermsConditionsPage from "./pages/DeclinedTermsConditionsPage";
import CreatePersonalProfilePage from "./pages/CreatePersonalProfilePage";
import LoginPage from "./pages/LoginPage";
import NotificationProvider from "./components/NotificationProvider";
import AuthGuard from "./AuthGuard";
import PublicOnlyGuard from "./PublicOnlyGuard";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import CreateNewPasswordPage from "./pages/CreateNewPasswordPage";
import ResetPasswordLayout from "./layouts/ResetPasswordLayout";
import RatingScaleFormPage from "./pages/RatingScaleFormPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";

initTranslations();
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route Component={() => <AuthGuard />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<MyStudentsPage />} />
          <Route path="/students" element={<MyStudentsPage />} />
          <Route path="/students/:id/*" element={<StudentProfilePage />} />
          <Route path="/students/:id/ratingScales/:ratingScaleId/pages/:pageId" element={<RatingScaleFormPage />} />
          <Route path="/help-center" element={<HelpCenterPage />} />
          <Route path="/*" element={<ErrorPage code={404} />} />
        </Route>
      </Route>

      <Route element={<RegistrationLayout />}>
        <Route element={<InvitationGuard type="NewInvitation" />}>
          <Route path="/invitation" element={<NewInvitationPage />} />
        </Route>
        <Route element={<InvitationGuard type="TermsAndConditions" />}>
          <Route path="/register/step1" element={<TermsAndConditionsPage />} />
        </Route>
        <Route element={<InvitationGuard type="DeclinedTermsConditions" />}>
          <Route path="/register/step1/declined" element={<DeclinedTermsConditionsPage />} />
        </Route>
        <Route element={<InvitationGuard type="CreateCredentials" />}>
          <Route path="/register/step2" element={<CreateCredentialsPage />} />
        </Route>
        <Route element={<InvitationGuard type="CreatePersonalProfile" />}>
          <Route path="/register/step3" element={<CreatePersonalProfilePage />} />
        </Route>
      </Route>

      <Route element={<PublicOnlyGuard />}>
        <Route element={<RegistrationLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        </Route>
      </Route>

      <Route element={<ResetPasswordLayout />}>
        <Route path="/passwordReset" element={<CreateNewPasswordPage />} />
      </Route>

      <Route element={<AnonymousLayout />}>
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Route>
    </>
  )
);

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RootContext.Provider value={state}>
        <NotificationProvider>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </NotificationProvider>
      </RootContext.Provider>
    </LocalizationProvider>
  );
};

export default App;
