import { SxProps } from "@mui/material";
import { RatingScalesFormQuestionType } from "../model/ratingScales.model";
import RatingScaleQuestionWithOptions from "./RatingScaleQuestionWithOptions";
import { QuestionComponentProps } from "./model/ratingScales.types";
import useQuestion from "../hooks/useQuestion";
import RatingScaleInputTextQuestion from "./RatingScaleInputTextQuestion";
import RatingScaleTextAreaQuestion from "./RatingScaleTextAreaQuestion";

const RatingScaleQuestion = (props: QuestionComponentProps & { sx?: SxProps }) => {
  const question = useQuestion(props.questionId);
  switch (question.type) {
    case RatingScalesFormQuestionType.CODING:
      return <RatingScaleQuestionWithOptions {...props} />;
    case RatingScalesFormQuestionType.STRING:
      return <RatingScaleInputTextQuestion {...props} />;
    case RatingScalesFormQuestionType.TEXT:
      return <RatingScaleTextAreaQuestion {...props} />;
    default:
      return <></>;
  }
};

export default RatingScaleQuestion;
