import { AppBar, Box } from "@mui/material";
import Header from "../Header";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { ErrorBoundary } from "../ErrorBoundary";
import ErrorPage from "../pages/ErrorPage";
import { getErrorState } from "../state/ErrorState";
import Breadcrumbs from "../Breadcrumbs";
import Footer from "../Footer";
import { observer } from "mobx-react-lite";
import { useAppState } from "../state/AppState";

const MainLayout = () => {
  const errorState = getErrorState();
  const { navigation } = useAppState();

  return (
    <Box sx={{ backgroundColor: navigation.backgroundColor, paddingTop: "130px" }}>
      <AppBar sx={{ boxShadow: "none", backgroundColor: "white" }}>
        <Header />
        <Breadcrumbs />
      </AppBar>
      <Box sx={{ minHeight: "calc(100vh - 150px)", height: "calc(100% - 150px)", paddingX: "5%", paddingTop: "20px", display: "flex", flexDirection: "column" }}>
        {errorState.code !== null ? (
          <>
            <ErrorPage code={errorState.code as number} />
            <Footer sx={{ marginTop: "auto" }} />
          </>
        ) : (
          <ErrorBoundary>
            <Outlet />
            <Footer sx={{ paddingTop: "40px", marginTop: "auto" }} />
          </ErrorBoundary>
        )}
      </Box>
      {!navigation.scrollRestorationDisabled && <ScrollRestoration />}
    </Box>
  );
};

export default observer(MainLayout);
