import { Box } from "@mui/material";
import TextHeader from "../components/TextHeader";
import TextLabel from "../components/TextLabel";
import RegistrationStepper from "../components/RegistrationStepper";
import { useTranslation } from "react-i18next";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import DefaultSecondaryButton from "../components/DefaultSecondaryButton";
import { useAppState } from "../state/AppState";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import DefaultInput from "../components/DefaultInput";
import { useState } from "react";
import { passwordValidationPatterns, validate } from "../util/validation";

const CreateCredentialsPage = () => {
  const { t } = useTranslation();
  const { user } = useAppState();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const disabledContinueBtn = passwordError.length > 0 || confirmPasswordError.length > 0 || !password || !confirmPassword;

  const handleBack = () => {
    navigate(`/register/step1${user.invitationUrl()}`);
  };

  const handleContinue = () => {
    if (!disabledContinueBtn) {
      user.password = password;
      navigate(`/register/step3${user.invitationUrl()}`);
    }
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);
    const error = validate(value, passwordValidationPatterns);
    if (error) {
      setPasswordError(t(error));
    } else if (confirmPassword && confirmPassword !== value) {
      setPasswordError("");
      setConfirmPasswordError(t("passwords-dont-match"));
    } else if (confirmPassword === value) {
      setPasswordError("");
      setConfirmPasswordError("");
    } else {
      setPasswordError("");
    }
  };

  const onConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    if (password !== value) {
      setConfirmPasswordError(t("passwords-dont-match"));
    } else if (password === value) {
      setPasswordError("");
      setConfirmPasswordError("");
    } else {
      setConfirmPasswordError("");
    }
  };

  return (
    <Box sx={{ paddingTop: "47px" }}>
      <TextLabel sx={{ paddingBottom: "2px", color: "rgba(78, 93, 105, 1)" }}>{t("register")}</TextLabel>
      <TextHeader sx={{ paddingBottom: "24px" }}>{t("credentials-for-account")}</TextHeader>
      <RegistrationStepper step={2} />
      <DefaultInput value={user.email as string} label={t("email")} disabled sx={{ width: "100%" }} />
      <DefaultInput
        label={t("password")}
        error={passwordError.length > 0}
        errorMsg={passwordError}
        value={password}
        password
        onChange={onPasswordChange}
        onSubmit={handleContinue}
        sx={{ width: "100%", marginTop: "34px" }}
      />
      <DefaultInput
        label={t("repeat-password")}
        error={confirmPasswordError.length > 0}
        errorMsg={confirmPasswordError}
        value={confirmPassword}
        onChange={onConfirmPasswordChange}
        password
        disabled={passwordError.length > 0 || !password}
        onSubmit={handleContinue}
        sx={{ width: "100%", marginTop: "34px" }}
      />
      <Box sx={{ paddingTop: "230px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DefaultSecondaryButton onClick={handleBack} sx={{ border: "none", padding: "0", height: "inherit", "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" } }}>
          {t("back")}
        </DefaultSecondaryButton>
        <DefaultPrimaryButton onClick={handleContinue} disabled={disabledContinueBtn} sx={{ width: "180px" }}>
          {t("continue")}
        </DefaultPrimaryButton>
      </Box>
    </Box>
  );
};

export default observer(CreateCredentialsPage);
