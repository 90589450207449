import { Api, ApiResponsePromise, withErrorHandling, withErrorPage } from "./Api";
import { QuestionsCount, RatingScalesFormPageContent, RatingScalesFormRequest, RatingScalesFormSummary } from "../model/ratingScales.model";
import { refreshAccessTokenHook } from "./responseHooks/refreshAccessTokenHook";

class RatingScalesApi extends Api {
  getRatingScalesRequests = (studentId: string): ApiResponsePromise<RatingScalesFormRequest[]> => this.get(`students/${studentId}/ratingScales`, withErrorPage());
  getRatingScalePageContent = (studentId: string, formId: string, pageId: string, locale: string): ApiResponsePromise<RatingScalesFormPageContent> =>
    this.get(`students/${studentId}/ratingScales/${formId}/pages/${pageId}?locale=${locale}`, withErrorHandling());
  getRatingScalesRequestFormSummary = (studentId: string, formId: string): ApiResponsePromise<RatingScalesFormSummary> =>
    this.get(`students/${studentId}/ratingScales/${formId}`, withErrorPage());
  createAnswer = (studentId: string, formId: string, questionId: string, answer: string): ApiResponsePromise<QuestionsCount> =>
    this.post(
      `students/${studentId}/ratingScales/${formId}/questions/${questionId}/answers`,
      withErrorHandling({
        requestPayload: {
          value: answer,
        },
      })
    );
  updateAnswer = (studentId: string, formId: string, questionId: string, answer: string): ApiResponsePromise<QuestionsCount> =>
    this.put(
      `students/${studentId}/ratingScales/${formId}/questions/${questionId}/answers`,
      withErrorHandling({
        requestPayload: {
          value: answer,
        },
      })
    );

  submitForm = (studentId: string, formId: string): ApiResponsePromise<QuestionsCount> =>
    this.put(`students/${studentId}/ratingScales/${formId}/submit`, withErrorHandling({ expectJsonResponse: false }));
}

export const ratingScalesApi = new RatingScalesApi().useResponseHooks([refreshAccessTokenHook]);
