import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import ContactPerson from "./ContactPerson";
import TextHeader from "./TextHeader";
import TextLabel from "./TextLabel";
import { Student } from "../model/student.model";
import { reformatDate, yearsPassedSinceDate } from "../util/date";
import { User } from "../model/user.model";
import { useTranslation } from "react-i18next";

const StudentProfile = ({ student }: { student: Student }) => {
  const { t } = useTranslation();
  const adminGuardian: User | undefined = student.guardians.find((guardian) => guardian.isAdmin);
  if (!adminGuardian) {
    throw new Error("Admin guardian not found");
  }
  return (
    <Box sx={{ borderRadius: "8px", border: "1px solid #D5DBE3", display: "flex", flexWrap: "wrap", backgroundColor: "rgba(255, 255, 255, 1)" }}>
      <Box sx={{ borderRadius: "8px 0px 0px 8px", flex: 0.8, padding: "32px" }}>
        <TextHeader>
          {student.firstName} {student.lastName}
        </TextHeader>
        <Box sx={{ marginTop: "16px", display: "flex", flexWrap: "wrap" }} columnGap={2} rowGap={4}>
          <Box sx={{ flex: 2 }}>
            <DefaultText sx={{ paddingBottom: "16px" }}>{t("basic-info")}</DefaultText>
            <Box sx={{ display: "flex", flexWrap: "wrap", maxWidth: "600px" }} columnGap={2} rowGap={4}>
              <Box sx={{ flex: 1, display: "grid", gridTemplateColumns: "repeat(2, max-content)" }} columnGap={4} rowGap={1}>
                <TextLabel>{t("first-name")}</TextLabel>
                <DefaultText>{student.firstName}</DefaultText>
                <TextLabel>{t("last-name")}</TextLabel>
                <DefaultText>{student.lastName}</DefaultText>
              </Box>
              <Box sx={{ flex: 1, display: "grid", gridTemplateColumns: "repeat(2, max-content)" }} columnGap={4} rowGap={1}>
                <TextLabel>{t("age")} </TextLabel>
                <DefaultText>{yearsPassedSinceDate(student.birthDate)}</DefaultText>
                <TextLabel>{t("gender")}</TextLabel>
                <DefaultText>{t(`gender.${student.gender}`)}</DefaultText>
              </Box>
              <Box sx={{ flex: 1, display: "grid", gridTemplateColumns: "repeat(2, max-content)" }} columnGap={4} rowGap={1}>
                <TextLabel>{t("date-of-birth")} </TextLabel>
                <DefaultText>{reformatDate(student.birthDate, "YYYY-MM-DD", "MM/DD/YYYY")}</DefaultText>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 0.2, background: "#F9F9F9", borderRadius: "0px 8px 8px 0px", padding: "32px 32px 24px 48px" }}>
        <ContactPerson name={`${adminGuardian.firstName} ${adminGuardian.lastName}`} email={adminGuardian.email} guardians={student.guardians} />
      </Box>
    </Box>
  );
};

export default StudentProfile;
