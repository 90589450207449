import { Outlet, Navigate, useSearchParams } from "react-router-dom";
import { useAppState } from "./state/AppState";
import { observer } from "mobx-react-lite";

interface InvitationGuardProps {
  type: "NewInvitation" | "TermsAndConditions" | "DeclinedTermsConditions" | "CreateCredentials" | "CreatePersonalProfile";
}

const InvitationGuard = ({ type }: InvitationGuardProps) => {
  const { user } = useAppState();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get("id");
  const invitationToken = searchParams.get("token")?.replace(/ /g, "+");

  if (user.invitationId !== invitationId || (user.invitationToken !== invitationToken && invitationId !== "null" && invitationToken !== "null")) {
    user.invitationToken = invitationToken as string;
    user.invitationId = invitationId;
  }
  let auth;
  let navigateTo;

  switch (type) {
    case "NewInvitation":
      auth = !user.registrationToken;
      navigateTo = `/register/step1${user.invitationUrl()}`;
      break;
    case "TermsAndConditions":
      auth = user.isInvited();
      navigateTo = `/invitation${user.invitationUrl()}`;
      break;
    case "DeclinedTermsConditions":
      auth = user.isInvited();
      navigateTo = `/register/step1${user.invitationUrl()}`;
      break;
    case "CreateCredentials":
      auth = user.isInvited() && user.acceptedTermsAndConditions;
      navigateTo = `register/step1${user.invitationUrl()}`;
      break;
    case "CreatePersonalProfile":
      auth = user.isInvited() && user.acceptedTermsAndConditions && !!user.password;
      navigateTo = `/register/step2${user.invitationUrl()}`;
      break;
    default:
      throw new Error(`Unhandled type: ${type}`);
  }

  return auth ? <Outlet /> : <Navigate to={navigateTo} />;
};

export default observer(InvitationGuard);
