import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { invitationApi } from "../api/InvitationApi";
import { InvitationData } from "../model/invitationData.model";
import { useAppState } from "../state/AppState";
import { withLoader } from "../hoc/withLoader";
import { getErrorState } from "../state/ErrorState";
import ExpiredInvitation from "../components/ExpiredInvitation";
import { observer } from "mobx-react-lite";
import { userApi } from "../api/UserApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { User } from "../model/user.model";
import InvitationCodeValidation from "../components/InvitationCodeValidation";

const LoadingCodeValidation = withLoader(InvitationCodeValidation);

const NewInvitationPage = () => {
  const [expiredInvitation, setExpiredInvitation] = useState<boolean>(false);
  const { user } = useAppState();
  const errorState = getErrorState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get("id");
  const invitationToken = searchParams.get("token")?.replace(/ /g, "+");
  const [accessData, setAccessData] = useState<InvitationData>();

  if (user.invitationId !== invitationId || (user.invitationToken !== invitationToken && invitationId !== "null" && invitationToken !== "null")) {
    user.invitationToken = invitationToken as string;
    user.invitationId = invitationId;
  }

  useEffect(() => {
    const getData = async () => {
      if (user.invitationId && user.invitationToken) {
        const response = await invitationApi.getAccessData(user.invitationId, user.invitationToken);
        if (response.ok) {
          const data = response.data as InvitationData;
          const meResponse = await userApi.me({ showLoginOnRefreshATFail: false });
          if (meResponse.ok && data.email !== (meResponse.data as User).email) {
            setExpiredInvitation(true);
          } else {
            setAccessData(data);
          }
        } else if (response.error.code === 403) {
          setExpiredInvitation(true);
        } else {
          errorState.code = response.error.code;
        }
      } else {
        errorState.code = 404;
      }
      setLoading(false);
    };
    getData();
  }, [errorState, user, navigate]);

  return (
    <>
      {expiredInvitation ? (
        <ExpiredInvitation />
      ) : (
        <LoadingCodeValidation accessData={accessData as InvitationData} isLoading={loading}>
          <Box sx={{ height: 600, width: "1024px", paddingTop: "80px" }}>
            <Skeleton variant="rounded" sx={{ height: "inherit" }} />
          </Box>
        </LoadingCodeValidation>
      )}
    </>
  );
};

export default observer(NewInvitationPage);
