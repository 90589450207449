import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { studentApi } from "../api/StudentApi";
import { Student } from "../model/student.model";
import { useAppState } from "../state/AppState";
import { Page } from "../state/NavigationState";

export default (): Student | null => {
  const { navigation } = useAppState();
  const { id } = useParams();
  const [student, setStudent] = useState<Student | null>(null);

  useEffect(() => {
    navigation.backgroundColor = "white";
    navigation.activePage = Page.MY_STUDENTS;
    navigation.breadcrumbsElements = [
      {
        labelId: "my-students",
        path: "/students",
      },
    ];
  }, [navigation]);

  useEffect(() => {
    const getData = async () => {
      setStudent((await studentApi.getStudent(id as string)).data);
    };
    getData();
  }, [id]);

  useEffect(() => {
    if (student) {
      navigation.breadcrumbsElements = [
        {
          labelId: "my-students",
          path: "/students",
        },
        {
          labelId: `${student.firstName} ${student.lastName}`,
        },
      ];
    }
  }, [navigation, student]);

  return student;
};
