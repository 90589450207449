import { Pagination, SxProps } from "@mui/material";

interface DefaultPaginationProps {
  setPage: (page: number) => void;
  page: number;
  count: number;
  sx?: SxProps;
}

const DefaultPagination = ({ setPage, count, page, sx }: DefaultPaginationProps) => {
  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value as number);
  };

  return (
    <Pagination
      page={page}
      onChange={handleChange}
      shape="rounded"
      count={count}
      sx={{
        padding: "8px 2px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "8px",
        "& .MuiPaginationItem-root": {
          fontSize: "16px",
          lineHeight: "24px",
          color: "rgba(4, 11, 16, 1)",
          width: "42px",
          height: "40px",
          borderRadius: "8px",
        },
        "& .Mui-selected": {
          backgroundColor: "rgba(145, 84, 201, 1) !important",
          color: "rgba(255, 255, 255, 1)",
        },
        ...sx,
      }}
    />
  );
};

export default DefaultPagination;
