import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultText from "./DefaultText";
import { reformatDate } from "../util/date";
import { DailyEvents, EventCategory } from "../model/event.model";
import ChipList from "./ChipList";
import EventChip from "./EventChip";
import EventModal from "./EventModal";
import DefaultModal from "./DefaultModal";
import { useState } from "react";
import TrashIcon from "./icons/TrashIcon";
import PencilIcon from "./icons/PencilIcon";
import DeleteConfirmation from "./DeleteConfirmation";
import { useAppState } from "../state/AppState";
import { TranslatedDictionaryEventType } from "../model/dictionary.model";
import LeaveConfirmation from "./LeaveConfirmation";
import { useParams } from "react-router-dom";
import { eventApi } from "../api/EventApi";

const StudentEvent = (item: DailyEvents) => {
  const { t } = useTranslation();
  const { studentEvents, dictionary, notification } = useAppState();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();

  const handleCloseEditModal = () => {
    if (studentEvents.closeModalConfirmation) {
      setIsConfirmationOpen(true);
    } else {
      setIsEditModalOpen(false);
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const deleteEvent = async () => {
    setLoading(true);
    const response = await eventApi.deleteEvent(id as string, item.date);
    if (response.ok) {
      studentEvents.deleteEvent(item);
      notification.addNotification({
        title: t("event-deleted"),
        message: t("event-deleted-message"),
      });
    }
    setLoading(false);
    setIsDeleteModalOpen(false);
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleLeave = () => {
    studentEvents.closeModalConfirmation = false;
    setIsConfirmationOpen(false);
    setIsEditModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        padding: "24px 32px",
        border: "1px solid rgba(232, 229, 255, 1)",
        borderRadius: "8px",
        backgroundColor: "white",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultText sx={{ fontSize: "21px", lineHeight: "24px", fontWeight: 700 }}>{reformatDate(item.date, "YYYY/MM/DD", "MM/DD/YYYY")}</DefaultText>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <IconButton onClick={handleEdit} sx={{ padding: "0" }}>
            <PencilIcon />
          </IconButton>
          <IconButton onClick={handleDelete} sx={{ padding: "0" }}>
            <TrashIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "16px" }}>
        <Box sx={{ flex: 1 }}>
          <DefaultText sx={{ paddingBottom: "16px" }}>{t("good-things-happened-this-day")}</DefaultText>
          <ChipList
            sx={{ gap: "8px" }}
            items={item.events
              .filter((event) => event.category === EventCategory.GOOD_DAY)
              .map((e, index) => (
                <EventChip event={dictionary.eventTypes.find((item) => item.key === e.type) as TranslatedDictionaryEventType} key={index} />
              ))}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <DefaultText sx={{ paddingBottom: "16px" }}>{t("bad-things-happened-this-day")}</DefaultText>
          <ChipList
            sx={{ gap: "8px" }}
            items={item.events
              .filter((event) => event.category === EventCategory.BAD_DAY)
              .map((e, index) => (
                <EventChip event={dictionary.eventTypes.find((item) => item.key === e.type) as any} key={index} />
              ))}
          />
        </Box>
      </Box>
      <DefaultModal modalTitle={t("edit-event")} isOpen={isEditModalOpen} onClose={handleCloseEditModal} sx={{ width: "808px" }}>
        <EventModal handleClose={handleCloseEditModal} trackedEvent={item} />
      </DefaultModal>
      <DefaultModal modalTitle={t("delete-tracked-event")} isOpen={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DeleteConfirmation handleClose={handleCloseDeleteModal} handleDelete={deleteEvent} loading={loading} />
      </DefaultModal>
      <DefaultModal modalTitle={t("leaving-the-page")} isOpen={isConfirmationOpen} onClose={handleCloseConfirmation}>
        <LeaveConfirmation handleClose={handleCloseConfirmation} handleLeave={handleLeave} />
      </DefaultModal>
    </Box>
  );
};

export default StudentEvent;
