import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import CalendarBlankIcon from "./icons/CalendarBlankIcon";
import { dayjsDate } from "../util/date";
import { Trans } from "react-i18next";
import WarningIcon from "./icons/Warning";

interface DefaultDatePickerProps {
  label?: string;
  disabled?: boolean;
  defaultValue?: Date;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (date: number) => void;
  onError?: (error: string) => void;
  errorMsg?: string;
  sx?: SxProps;
}

const DefaultDatePicker = ({ label, defaultValue, value, minDate, maxDate, disabled, onChange, onError, errorMsg, sx }: DefaultDatePickerProps) => {
  return (
    <div>
      {label && <DefaultText sx={{ marginBottom: "8px" }}>{label}</DefaultText>}
      <DatePicker
        minDate={dayjsDate(minDate as Date)}
        maxDate={dayjsDate(maxDate as Date)}
        onChange={(date) => onChange && onChange(date?.valueOf() as number)}
        disabled={disabled}
        defaultValue={defaultValue && dayjsDate(defaultValue)}
        value={value && dayjsDate(value)}
        onError={(error) => onError && onError(error as string)}
        slots={{
          openPickerIcon: CalendarBlankIcon,
        }}
        sx={{
          width: "320px",
          backgroundColor: disabled ? "rgba(222, 227, 233, 1)" : "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          "& .MuiInputBase-input": {
            height: "24px",
            padding: "12px 16px",
          },
          "& .MuiButtonBase-root": {
            paddingRight: "14px",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },
          ...sx,
        }}
      />
      {errorMsg && (
        <Box sx={{ paddingTop: "8px", display: "flex" }}>
          <WarningIcon sx={{ paddingRight: "7px" }} />
          <DefaultText sx={{ fontSize: "12px", color: "rgba(221, 49, 49, 1)" }}>
            <Trans i18nKey={errorMsg} />
          </DefaultText>
        </Box>
      )}
    </div>
  );
};

export default DefaultDatePicker;
