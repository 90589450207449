import { Box } from "@mui/material";
import DefaultText from "./DefaultText";
import { StudentListEntry } from "../model/student.model";
import { useTranslation } from "react-i18next";
import { reformatDate } from "../util/date";
import CaretRightIcon from "./icons/CaretRightIcon";
import TextHeader from "./TextHeader";
import EmptyStudentsList from "./EmptyStudentsList";
import { useEffect, useState } from "react";
import DefaultPagination from "./DefaultPagination";
import DefaultTableRow from "./DefaultTableRow";

export const RECORDS_PER_PAGE = 12;

interface StudentsListProps {
  students: StudentListEntry[];
}

const StudentsList = ({ students }: StudentsListProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [studentsToDisplay, setStudentsToDisplay] = useState<StudentListEntry[]>([]);

  useEffect(() => {
    const start = (page - 1) * RECORDS_PER_PAGE;
    const end = start + RECORDS_PER_PAGE;
    setStudentsToDisplay(students.slice(start, end));
  }, [page, students]);

  useEffect(() => {
    const totalPages = Math.ceil(students.length / RECORDS_PER_PAGE);
    setNumberOfPages(totalPages);
  }, [students]);

  const handlePageChange = (page: number) => {
    const topElement = document.querySelector("#top");
    if (topElement) {
      topElement.scrollIntoView();
    }
    setPage(page);
  };

  return (
    <>
      <Box id="top" style={{ marginTop: "-150px", marginBottom: "150px" }} />
      <TextHeader sx={{ fontSize: "32px", lineHeight: "40px", paddingTop: "30px", paddingBottom: "55px" }}>{t("my-students")}</TextHeader>
      {students.length > 0 && (
        <>
          <Box sx={{ display: "flex", direction: "row", marginBottom: "4px", padding: "10px 16px", minWidth: "800px" }}>
            <DefaultText sx={{ flex: 2 }}>{t("student")}</DefaultText>
            <DefaultText sx={{ flex: 1 }}>{t("gender")}</DefaultText>
            <DefaultText sx={{ flex: 1, minWidth: "115px" }}>{t("date-of-birth")}</DefaultText>
            <DefaultText sx={{ flex: 3 }}>{t("main-contact-person")}</DefaultText>
          </Box>
          {studentsToDisplay.map((student, index) => (
            <DefaultTableRow
              link={`/students/${student.uuid}`}
              key={index}
              sx={{
                cursor: "pointer",
              }}
            >
              <Box sx={{ flex: 2 }}>
                <DefaultText>
                  {student.lastName}, {student.firstName}
                </DefaultText>
              </Box>
              <Box sx={{ flex: 1 }}>
                <DefaultText>{t(`gender.${student.gender}`)}</DefaultText>
              </Box>
              <Box sx={{ flex: 1, minWidth: "115px" }}>
                <DefaultText>{reformatDate(student.birthDate, "YYYY-MM-DD", "MM/DD/YYYY")}</DefaultText>
              </Box>
              <Box sx={{ flex: 3, display: "flex", justifyContent: "space-between" }}>
                <DefaultText>
                  {student.adminGuardianFirstName} {student.adminGuardianLastName}
                </DefaultText>
                <Box
                  sx={{
                    backgroundColor: "rgba(231, 234, 238, 1)",
                    width: "79px",
                    marginRight: "-16px",
                    marginY: "-14px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0px 4px 4px 0px",
                  }}
                >
                  <CaretRightIcon />
                </Box>
              </Box>
            </DefaultTableRow>
          ))}
          {students.length > RECORDS_PER_PAGE && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <DefaultPagination count={numberOfPages} page={page} setPage={handlePageChange} sx={{ marginTop: "28px" }} />
            </Box>
          )}
        </>
      )}
      {students.length === 0 && <EmptyStudentsList />}
    </>
  );
};

export default StudentsList;
