import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoteBlankIcon from "./icons/NoteBlankIcon";
import { observer } from "mobx-react-lite";
import DefaultPrimaryButton from "./DefaultPrimaryButton";
import DefaultText from "./DefaultText";
import { useState } from "react";
import DefaultModal from "./DefaultModal";
import EventModal from "./EventModal";
import LeaveConfirmation from "./LeaveConfirmation";
import { useAppState } from "../state/AppState";

const EventsTypeSelectorTab = () => {
  const { t } = useTranslation();
  const { studentEvents } = useAppState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleTrackEvent = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    if (studentEvents.closeModalConfirmation) {
      setIsConfirmationOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleLeave = () => {
    studentEvents.closeModalConfirmation = false;
    setIsConfirmationOpen(false);
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "34px 16px", alignItems: "center", justifyContent: "space-between" }}>
      <DefaultText sx={{ fontSize: "18px", lineHeight: "24px", color: "rgba(78, 93, 105, 1)" }}>{t("events-tab-information")}</DefaultText>
      <DefaultPrimaryButton onClick={handleTrackEvent} sx={{ width: "256px", alignSelf: "flex-end" }} startIcon={<NoteBlankIcon />}>
        {t("track-event")}
      </DefaultPrimaryButton>
      <DefaultModal modalTitle={t("track-event")} isOpen={isModalOpen} onClose={handleCloseModal} sx={{ width: "808px" }}>
        <EventModal handleClose={handleCloseModal} />
      </DefaultModal>
      <DefaultModal modalTitle={t("leaving-the-page")} isOpen={isConfirmationOpen} onClose={handleCloseConfirmation}>
        <LeaveConfirmation handleClose={handleCloseConfirmation} handleLeave={handleLeave} />
      </DefaultModal>
    </Box>
  );
};

export default observer(EventsTypeSelectorTab);
