import { Box, Skeleton, SxProps } from "@mui/material";
import DefaultText from "./DefaultText";
import { observer } from "mobx-react-lite";
import { useAppState } from "../state/AppState";
import { Trans } from "react-i18next";
import BorderLinearProgress from "./BorderLinearProgress";

const RatingScalesFormProgressCard = ({ sx }: { sx?: SxProps }) => {
  const { ratingScaleFormState } = useAppState();

  if (!ratingScaleFormState.page) {
    return (
      <Box sx={{ display: "flex", ...sx }}>
        <Skeleton variant="rounded" sx={{ height: "inherit", flex: 1 }} />
      </Box>
    );
  }

  const { page, firstQuestionNumber, lastQuestionNumber } = ratingScaleFormState;
  const { sectionName, totalCount, completedCount } = page;

  return (
    <Box
      sx={{
        padding: "16px",
        paddingLeft: "32px",
        paddingRight: "32px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "8px",
        border: "1px solid rgba(213, 219, 227, 1)",
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultText sx={{ fontWeight: "700", fontSize: "16px", lineHeight: "24px", marginBottom: "8px" }}>{sectionName}</DefaultText>
        {firstQuestionNumber && lastQuestionNumber && (
          <DefaultText sx={{ color: "rgba(78, 93, 105, 1)", marginTop: "8px", fontWeight: 700 }}>
            <Trans i18nKey="questions-range" values={{ firstQuestionNumber, lastQuestionNumber }} />
          </DefaultText>
        )}
      </Box>
      <BorderLinearProgress value={Math.floor((completedCount * 100) / totalCount)} sx={{ width: "100%" }} />
      <DefaultText sx={{ fontSize: "12px", marginTop: "8px" }}>
        <Trans i18nKey="questions-completed" values={{ totalCount, completedCount }} />
      </DefaultText>
    </Box>
  );
};

export default observer(RatingScalesFormProgressCard);
