import { SxProps } from "@mui/material";
import RatingScaleQuestionLayout from "./RatingScaleQuestionLayout";
import { QuestionComponentProps } from "./model/ratingScales.types";
import DefaultInput from "./DefaultInput";
import useQuestion from "../hooks/useQuestion";
import useAnswerApi from "../hooks/useAnswerApi";
import { observer } from "mobx-react-lite";
import { useAppState } from "../state/AppState";
import SelectedAnswerText from "./SelectedAnswerText";

const RatingScaleInputTextQuestion = (props: QuestionComponentProps & { sx?: SxProps }) => {
  const { questionId } = props;
  const question = useQuestion(questionId);
  const { sendDebouncedAnswer, isLoading } = useAnswerApi(question);
  const { ratingScaleFormState } = useAppState();
  const { isFormSubmitted } = ratingScaleFormState;
  return (
    <RatingScaleQuestionLayout {...props}>
      {!isFormSubmitted ? (
        <DefaultInput
          disabled={isFormSubmitted}
          value={question.textAnswer || ""}
          sx={{ width: "300px" }}
          maxLength={20}
          onChange={async (answer) => {
            await sendDebouncedAnswer(answer);
          }}
          isLoading={isLoading}
        />
      ) : (
        <SelectedAnswerText>{question.textAnswer}</SelectedAnswerText>
      )}
    </RatingScaleQuestionLayout>
  );
};

export default observer(RatingScaleInputTextQuestion);
