import { Box } from "@mui/material";
import DefaultText from "../components/DefaultText";
import TextHeader from "../components/TextHeader";
import { useTranslation } from "react-i18next";
import DefaultPrimaryButton from "../components/DefaultPrimaryButton";
import DefaultSecondaryButton from "../components/DefaultSecondaryButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DefaultInput from "../components/DefaultInput";
import { validate, patterns } from "../util/validation";
import PurpleArrowLeftIcon from "../components/icons/PurpleArrowLeftIcon";
import CheckYourMail from "../components/CheckYourMail";
import { userApi } from "../api/UserApi";
import { getErrorState } from "../state/ErrorState";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const errorState = getErrorState();

  const handleContinue = async () => {
    if (email) {
      setLoading(true);
      const response = await userApi.resetPasswordInit(email);
      if (!response.ok && response.error.code !== 400) {
        errorState.code = response.error.code;
      }
      setEmailSent(true);
      setLoading(false);
    }
  };

  const handleBack = () => {
    setEmailErrorMsg("");
    setEmailSent(false);
  };

  const onEmailChange = (value: string) => {
    setEmail(value);
    const error = validate(value, [patterns.requiredField]);
    if (error) {
      setEmailErrorMsg(t(error));
    } else {
      setEmailErrorMsg("");
    }
  };
  return (
    <Box sx={{ paddingTop: "160px", maxWidth: "530px" }}>
      {!emailSent ? (
        <>
          <TextHeader sx={{ paddingBottom: "10px" }}>{t("restore-password")}</TextHeader>
          <DefaultText sx={{ fontSize: "16px", lineHeight: "24px" }}>{t("enter-email-restore-password")}</DefaultText>
          <DefaultInput
            label={t("email")}
            value={email}
            onChange={onEmailChange}
            placeholder={t("enter-email")}
            error={!!emailErrorMsg}
            errorMsg={emailErrorMsg}
            onSubmit={handleContinue}
            sx={{ paddingTop: "32px", paddingBottom: "48px" }}
          />
          <DefaultPrimaryButton onClick={handleContinue} disabled={email.length < 1 || loading} isLoading={loading} sx={{ width: "280px", marginBottom: "176px" }}>
            {t("continue")}
          </DefaultPrimaryButton>
          <DefaultSecondaryButton
            startIcon={<PurpleArrowLeftIcon />}
            onClick={() => navigate(`/login`)}
            sx={{ border: "none", padding: "0", height: "inherit", "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" } }}
          >
            {t("back-to-login")}
          </DefaultSecondaryButton>
        </>
      ) : (
        <CheckYourMail email={email} handleBack={handleBack} />
      )}
    </Box>
  );
};

export default ForgotPasswordPage;
